import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { environment } from '@fnc-env/environment';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  private startLoading: number;
  private currentPath: string;
  private loadingPath: string;

  constructor(
    private readonly router: Router,
    private readonly angularticsGoogleGST: Angulartics2GoogleGlobalSiteTag
  ) {}

  startTracking() {
    if (!environment.gaTrackingId) {
      return;
    }

    this.addGAScript();
    this.startPageTracking();
    this.startLoadTracking();
  }

  private addGAScript() {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`);
    gaScript.async = true;
    document.head.prepend(gaScript);
    window.dataLayer = window.dataLayer || [];
    // Please don't refactor to an arrow function - this will break Google Analytics
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', environment.gaTrackingId);
  }

  private startLoadTracking() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event): event is NavigationStart | NavigationEnd => event instanceof NavigationEnd || event instanceof NavigationStart)
      )
      .subscribe((event: RouterEvent) => {
        const mainUrl = event.url.split('?')[0];

        if (event instanceof NavigationStart) {
          this.startLoading = Date.now();
        }

        if (event instanceof NavigationEnd && mainUrl !== this.loadingPath) {
          this.loadingPath = mainUrl;
          this.angularticsGoogleGST.userTimings({
            name: mainUrl,
            value: Date.now() - this.startLoading,
            category: mainUrl
          });
        }
      });
  }

  private startPageTracking() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects.split('?')[0]),
        filter(path => this.currentPath !== path)
      )
      .subscribe(path => {
        this.currentPath = path;
        this.angularticsGoogleGST.pageTrack(path);
      });
  }
}
