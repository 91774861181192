import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { Cell } from '@fnc-shared/components/cells/cell';

@Component({
  selector: 'fnc-formatted-number-cell',
  template: `
    <ng-template [fncTemplateRequestor]="component" let-value="value">
      {{ value | fncFormatNumber | mmAddSign | mmAddDash }}
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormattedNumberCellComponent implements Cell {
  templateRef: TemplateRef<ImplicitAny>;
  component = this;
}
