import { FilterTypes } from './filter-types.constant';
import { FilterNames } from '@fnc-shared/models/filter-names';

export const binaryFiltersMap = {
  [FilterNames.APPROVED]: FilterTypes.BINARY,
  [FilterNames.ASSORTMENT]: FilterTypes.BINARY,
  [FilterNames.CLEARED]: FilterTypes.BINARY,
  [FilterNames.COMMENTS_PRESENCE]: FilterTypes.BINARY,
  [FilterNames.FILE_UPLOADED]: FilterTypes.BINARY,
  [FilterNames.HAS_ERRORS]: FilterTypes.BINARY,
  [FilterNames.HAS_WARNINGS]: FilterTypes.BINARY,
  [FilterNames.IS_AUTOMATIC]: FilterTypes.BINARY,
  [FilterNames.IS_AUTO_SOURCE_TYPE]: FilterTypes.BINARY,
  [FilterNames.IS_COMMITMENT_OPEN]: FilterTypes.BINARY,
  [FilterNames.IS_CORRECTED]: FilterTypes.BINARY,
  [FilterNames.IS_INVOICE_CREATED]: FilterTypes.BINARY,
  [FilterNames.IS_IWT]: FilterTypes.BINARY,
  [FilterNames.IS_LATE_FEE_INCLUDED]: FilterTypes.BINARY,
  [FilterNames.IS_LATE_ORDER]: FilterTypes.BINARY,
  [FilterNames.IS_OPEN]: FilterTypes.BINARY,
  [FilterNames.IS_OUT_OF_STOCK]: FilterTypes.BINARY,
  [FilterNames.IS_STOCK_IN_TRANSIT]: FilterTypes.BINARY,
  [FilterNames.PAYMENTS_BLOCKED]: FilterTypes.BINARY,
  [FilterNames.PAID]: FilterTypes.BINARY,
  [FilterNames.IS_IMPORTANT]: FilterTypes.BINARY
};
