<ng-container *ngIf="plcStatuses.length > 0; else plcStatusesEmpty">
  <div class="fnc-plc-statuses">
    <a (click)="checkStatusAction($event)" class="fnc-plc-statuses__check-status">
      {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PLC_STATUS" | translate }}
    </a>

    <ul class="fnc-plc-statuses__list">
      <li *ngFor="let plcStatus of plcStatuses; let i = index">
        <mm-icon
          [tooltip]="plcToolTipTemplate"
          [placement]="'left'"
          [color]="getStatusIcon(plcStatus.status)"
          kind="bullet"
          size="0.5"
        ></mm-icon>
        <ng-container
          [ngTemplateOutlet]="isElementPreferredInventory(i) ? preferredInventory : defaultInventory"
          [ngTemplateOutletContext]="{ plcStatus: plcStatus, hasNext: plcStatuses.length > 1 }"
        >
        </ng-container>
      </li>
    </ul>
  </div>
</ng-container>

<ng-template #plcStatusesEmpty>
  <span class="fnc-plc-statuses__empty-label">{{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PLC_STATUS" | translate }}:</span>
  {{ dashSymbol }}
</ng-template>

<ng-template #preferredInventory let-plcStatus="plcStatus" let-hasNext="hasNext">
  <span [tooltip]="plcPreferredInventoryToolTipTemplate" class="fnc-plc-statuses__preferred-inventory__inventory-name">{{
    plcStatus.inventoryName
  }}</span>
  <mm-icon
    class="fnc-plc-statuses__preferred-inventory__icon-after"
    *ngIf="hasNext"
    kind="arrow-right"
    [size]="0.6"
    color="black"
  ></mm-icon>
</ng-template>

<ng-template #defaultInventory let-plcStatus="plcStatus">
  <span class="fnc-plc-statuses__inventory-name">{{ plcStatus.inventoryName }}</span>
</ng-template>

<ng-template #plcPreferredInventoryToolTipTemplate>
  <div class="fnc-plc-statuses__tooltip-container">
    <div class="fnc-plc-statuses__tooltip-container--text">
      {{ "SUPPLY_CHAIN.REPLENISHMENT.TOOLTIPS.PREFERRED_INVENTORY" | translate }}
    </div>
  </div>
</ng-template>

<ng-template #plcToolTipTemplate>
  <div class="fnc-plc-statuses__tooltip-container">
    <div class="fnc-plc-statuses__tooltip-container--text">
      {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TOOLTIP_LIST.HEADER" | translate }}
    </div>
    <div class="fnc-plc-statuses__tooltip-container--text">
      <mm-icon [color]="'green'" kind="bullet" size="0.5"></mm-icon>
      {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TOOLTIP_LIST.ACTIVE" | translate }}
    </div>
    <div class="fnc-plc-statuses__tooltip-container--text">
      <mm-icon [color]="'orange'" kind="bullet" size="0.5"></mm-icon>
      {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TOOLTIP_LIST.DELIST_UNDECIDED" | translate }}
    </div>
    <div class="fnc-plc-statuses__tooltip-container--text">
      <mm-icon [color]="'red'" kind="bullet" size="0.5"></mm-icon>
      {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TOOLTIP_LIST.INACTIVE" | translate }}
    </div>
  </div>
</ng-template>
