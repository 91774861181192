import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TreeStatus } from '@mm-ui/components';
import { ColumnConfig, TableConfig, TreeAction } from '@mm-ui/components/lib/components/datatable/models/datatable.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { AuditLogTableService } from './audit-log-table.service';
import { AuditLogService } from '@fnc-shared/services/audit-log/audit-log.service';
import { AuditLogConfig, AuditLogTableItem } from '@fnc-shared/models/audit-log';
import { AuditLogApp, AuditLogEntity } from '@fnc-shared/constants/audit-log.constant';

@UntilDestroy()
@Component({
  selector: 'fnc-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AuditLogTableService]
})
export class AuditLogComponent implements OnInit {
  @Input() entityId: number;
  @Input() entityType: AuditLogEntity;
  @Input() application: AuditLogApp;
  @Input() config: AuditLogConfig;
  @Input() updateEvent$: Observable<void>;

  tableConfig: Partial<TableConfig>;
  tableColumnsConfig: ColumnConfig[];
  tableRows = new BehaviorSubject([]);
  isLoading = new BehaviorSubject(true);
  stopUpdating = new Subject();
  parentRows: AuditLogTableItem[] = [];
  childRows: AuditLogTableItem[] = [];
  isOpenedLog = false;

  private readonly updateTimeShift = 3000;

  constructor(
    private readonly auditLogTableService: AuditLogTableService,
    private readonly auditLogService: AuditLogService
  ) {}

  ngOnInit() {
    this.tableConfig = this.auditLogTableService.getTableConfig();
    this.tableColumnsConfig = this.auditLogTableService.getColumnsConfig(this.config);
  }

  onToggleLog() {
    this.isOpenedLog = !this.isOpenedLog;

    if (this.isOpenedLog) {
      this.loadAuditLogData();

      this.updateEvent$?.pipe(untilDestroyed(this), takeUntil(this.stopUpdating)).subscribe(() => {
        this.loadAuditLogData();
      });
    } else {
      this.stopUpdating.next(null);
    }
  }

  onTreeAction(event: TreeAction<AuditLogTableItem>) {
    const index = event.rowIndex;

    if (this.parentRows[index].treeStatus === TreeStatus.COLLAPSED) {
      this.parentRows = this.parentRows.map(row => ({
        ...row,
        treeStatus: row.treeStatus === TreeStatus.DISABLED ? TreeStatus.DISABLED : TreeStatus.COLLAPSED
      }));
      this.parentRows[index].treeStatus = TreeStatus.EXPANDED;
      this.tableRows.next([...this.parentRows, ...this.childRows.filter(row => row.parentId === this.parentRows[index].rowId)]);
    } else {
      this.parentRows[index].treeStatus = TreeStatus.COLLAPSED;
      this.tableRows.next([...this.parentRows]);
    }
  }

  private loadAuditLogData() {
    this.isLoading.next(true);

    of(null)
      .pipe(
        // TODO: To be discussed. Ideally would be better to create some notification
        // in the Audit-Log microservice, which will give us information
        // about the moment when the data has been written into the history DB.
        // For the MVP version simple delay would be enough
        delay(this.updateTimeShift),
        switchMap(() => this.auditLogService.getAuditLogData(this.entityId, this.entityType, this.application, this.config)),
        finalize(() => this.isLoading.next(false))
      )
      .subscribe(rows => {
        this.parentRows = rows.filter(row => !row.parentId);
        this.childRows = rows.filter(row => !!row.parentId);
        this.tableRows.next(this.parentRows);
      });
  }
}
