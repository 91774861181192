import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MmLoaderService, ApiService } from '@mm-ui/core';
import { finalize } from 'rxjs/operators';
import { Path } from '@fnc-shared/constants/path.constant';

@Component({
  templateUrl: './signin-page.component.html'
})
export class SigninPageComponent {
  form: FormGroup;

  constructor(
    private readonly loader: MmLoaderService,
    private readonly formBuilder: FormBuilder,
    private readonly api: ApiService,
    private readonly router: Router
  ) {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
    this.router.onSameUrlNavigation = 'ignore';
  }

  onSubmit() {
    const { username, password } = this.form.value;
    const request = { username, password };
    this.loader.startGlobal();
    this.api
      .post('v1/login', {}, request)
      .pipe(finalize(() => this.loader.stopForce()))
      .subscribe(response => {
        document.cookie = `apiKey=${response.apiKey as string}; path=/`;
        void this.router.navigate([Path.home]).then(() => {
          this.router.onSameUrlNavigation = 'reload';
        });
      });
  }
}
