import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { camelCase, isEmpty, isEqual } from 'lodash';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FieldResponseError, MapDataItem } from '@fnc-shared/services/interfaces/http-response-map.interface';

@Injectable({ providedIn: 'root' })
export class HttpResponseMapService {
  constructor(private readonly translate: TranslateService) {}

  getErrorTranslations(mappedError: FieldResponseError[], defaultData: object) {
    return combineLatest(this.getParamsTranslations(mappedError, this.translate, defaultData)).pipe(
      switchMap(translatedParams => combineLatest(this.getMessagesTranslations(translatedParams, defaultData)))
    );
  }

  mapDataToParameters(this: void, error: FieldResponseError[], data: MapDataItem[]) {
    const mappedErrorData: FieldResponseError[] = [];
    error
      .map(err => {
        err.field = err.field
          .split('.')
          .map(substr => camelCase(substr))
          .join('.');

        const matched = data.find(item => (item.fieldRegexp ? err.field.match(item.fieldRegexp) : err.field === item.field));

        return matched
          ? {
              ...err,
              ...matched,
              parameters: { ...err.parameters, ...matched.parameters }
            }
          : err;
      })
      .forEach(err => {
        if (!mappedErrorData.find(el => el.field === err.field && isEqual(el, err))) {
          mappedErrorData.push(err);
        }
      });

    return mappedErrorData;
  }

  private getParamsTranslations(errorData: FieldResponseError[], translate: TranslateService, defaultData: object) {
    const groupOfErrorsWithParams: Observable<FieldResponseError>[] = [];

    errorData.forEach(errorItem => {
      const groupOfParamsByError: Observable<RecordOf<string>>[] = [];
      if (errorItem.parameters && Object.keys(errorItem.parameters)?.length) {
        for (const [key, value] of Object.entries(errorItem.parameters)) {
          const translation =
            typeof value === 'function' ? value(translate, defaultData as RecordOf<string>) : translate.get(value.toString(), defaultData);
          groupOfParamsByError.push(this.mapKeyWithTranslation(translation, key));
        }

        groupOfErrorsWithParams.push(this.matchTranslationsWithParams(combineLatest(groupOfParamsByError), errorItem));
      } else {
        groupOfErrorsWithParams.push(of(errorItem));
      }
    });

    return groupOfErrorsWithParams;
  }

  private mapKeyWithTranslation(translation: Observable<string>, key: string): Observable<RecordOf<string>> {
    return translation.pipe(map(translatedParamValue => ({ [key]: translatedParamValue })));
  }

  private matchTranslationsWithParams(translations: Observable<RecordOf<string>[]>, errorItem: FieldResponseError) {
    return translations.pipe(
      map((translationArray: RecordOf<string>[]) => {
        for (const property of Object.keys(errorItem.parameters)) {
          errorItem.parameters[property] = translationArray.find(keyValue => Object.keys(keyValue)[0] === property)[property];
        }

        return errorItem;
      })
    );
  }

  private getMessagesTranslations(errorData: FieldResponseError[], defaultData: object): Observable<string>[] {
    return errorData.map(e =>
      !isEmpty(e.message) ? this.translate.get(e.message, { ...e.parameters, ...defaultData }) : of('')
    ) as Observable<string>[];
  }
}
