import { ProductPromotionStatus } from '@fnc-app/modules/shared-replenishment/state/product-promotion.model';
import { SupportedLanguagesCodes } from '@fnc-core/services/settings/settings.service';
import { FilterNames } from '@fnc-shared/models/filter-names';
import { ProductClassification, ServiceLevelClassification } from '@fnc-shared-supply-chain/constants/classifications.constant';
import { PLCStatusMultiStep } from '@fnc-shared/components/plc-status-multi-step/plc-status-multi-step.model';

export interface Replenishment {
  activeOfferCount: number;
  activeWarehouseStockCount: number;
  blockedReason: Nullable<string>;
  blockedUntil?: string | Date;
  category: ReplenishmentProductCategory;
  classification: ProductClassification;
  comment?: string;
  committedQuantity?: number;
  dailyForecast: number;
  draftQuantity: number;
  expectedSales: number;
  gtin: string;
  hasDrafts: boolean;
  hasSupplyOrders: boolean;
  id?: number;
  inactiveOfferCount: number;
  isAutomatic: boolean;
  isBestOfferForInventory?: boolean;
  isBlocked: boolean;
  nextEta: string;
  orderedQuantity?: number;
  outOfStockAt: string;
  productName: string;
  promotion: ReplenishmentProductPromotion;
  sellOutInDays: number;
  sku: string;
  stock: number;
  stockCoverage: number;
  stockInTransit: number;
  stockOnHold: StockOnHold;
  vendorAvailableQuantity?: number;
  warehouseAvailableQuantity?: number;
  serviceLevel?: ServiceLevelClassification;
  plcStatus?: PLCStatus;
  plcStatuses?: PLCStatusMultiStep[];
  preferredInventoryName: string | null;
}

export interface ReplenishmentExtraResponse extends RecordNested {
  skus: string[];
}

export interface Offer {
  inventoryName: string;
  vendorName: string;
  packagingUnits: number;
  pricePerItem: number;
  isBestOffer: boolean;
  leadTime?: WeakNumber;
  inventoryStock?: number;
  isActive: boolean;
}

export interface WarehouseStock {
  name: string;
  packagingUnits: number;
  pricePerItem: number;
  leadTime?: WeakNumber;
  inventoryStock: number;
}

export interface ReplenishmentProductCategory {
  id: string;
  names: ReplenishmentProductCategoryName[];
  parent: ReplenishmentProductCategory;
}

export interface ReplenishmentProductCategoryName {
  locale: SupportedLanguagesCodes;
  name: string;
}

export interface ReplenishmentProductPromotion {
  id: number;
  externalId: number;
  startDate: string | Date;
  endDate: string | Date;
  status: ProductPromotionStatus;
  name: string;
}

export interface BlockedProductsResponse {
  productsBlocked: Partial<Replenishment>[];
}

export interface UnblockedProductsResponse {
  productsUnblocked: Partial<Replenishment>[];
}

export interface SupplyBasedOnReplenishmentProduct {
  id: WeakNumber;
  eta: string;
  inventory: string;
  quantity: number;
  vendor: number;
  isLateDelivery: boolean;
  type: SupplyEntityTypes;
}

export interface SupplyBasedOnReplenishmentProductResponse {
  data: SupplyBasedOnReplenishmentProduct[];
  extra: {
    totalQuantity: number;
  };
}

export interface StockOnHold {
  total: number;
  statuses: StockOnHoldStatus[];
}

export interface StockOnHoldStatus {
  name: string;
  quantity: number;
}

export interface ReplenishmentConfigForPostRequestByFilters {
  key: FilterNames;
  data: RecordNested;
}

export enum ReplenishmentActionsMenuElement {
  BLOCK_PRODUCTS = 'blockProducts',
  UNBLOCK_PRODUCTS = 'unblockProducts',
  ASSIGN_LABELS = 'assignLabels'
}

export enum SupplyEntityTypes {
  ORDER_DRAFT = 'ORDER_DRAFT',
  SUPPLY_ORDER = 'SUPPLY_ORDER'
}

export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  NO_OFFERED_STOCK = 'NO_OFFERED_STOCK'
}

export enum OutOfStockStatus {
  IS_OUT_OF_STOCK = 'IS_OUT_OF_STOCK',
  IS_NOT_OUT_OF_STOCK = 'IS_NOT_OUT_OF_STOCK'
}

export enum AutomaticStatus {
  IS_AUTOMATIC = 'IS_AUTOMATIC',
  IS_NOT_AUTOMATIC = 'IS_NOT_AUTOMATIC'
}

export enum StockInTransit {
  IS_STOCK_IN_TRANSIT = 'IS_STOCK_IN_TRANSIT',
  IS_NOT_STOCK_IN_TRANSIT = 'IS_NOT_STOCK_IN_TRANSIT'
}

export enum ReplenishmentModeTab {
  GENERAL = 'GENERAL',
  PROMOTION = 'PROMOTION',
  COMMITMENT = 'COMMITMENT'
}

export enum ProductCommitmentValue {
  ALL = 'ALL'
}

export enum IsCommitmentOpen {
  IS_COMMITMENT_OPEN = 'IS_COMMITMENT_OPEN',
  IS_NOT_COMMITMENT_OPEN = 'IS_NOT_COMMITMENT_OPEN'
}

export enum FileUploaded {
  FILE_UPLOADED = 'FILE_UPLOADED'
}

export enum PLCStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  MIXED = 'MIXED'
}
