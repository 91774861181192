import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { allowedFileTypes } from '@fnc-shared/components/modal-file-manager-window/file-manager.constant';

const FileActionsBase = {
  DOWNLOAD: {
    label: _('COMMON.FILE_ACTIONS.DOWNLOAD'),
    cmd: 'downloadFile',
    params: {}
  },
  OPEN: {
    label: _('COMMON.FILE_ACTIONS.OPEN'),
    cmd: 'openFile',
    params: {}
  },
  REPLACE: {
    label: _('COMMON.FILE_ACTIONS.REPLACE'),
    cmd: 'replaceFile',
    params: {}
  },
  UPLOAD: {
    label: _('COMMON.FILE_ACTIONS.UPLOAD'),
    cmd: 'uploadFile',
    params: {}
  },
  DELETE: {
    label: _('COMMON.FILE_ACTIONS.DELETE'),
    cmd: 'deleteFile',
    params: {}
  }
};

export const NoFileMessages: Record<string, string> = {
  VENDOR_INVOICE: _('HEALTH_CHECK.CONFLICTS.INVOICE_FILE'),
  VENDOR_INVOICE_EDI: _('HEALTH_CHECK.CONFLICTS.INVOICE_FILE'),
  VENDOR_NOTE: _('HEALTH_CHECK.CONFLICTS.NOTE_FILE'),
  VENDOR_NOTE_EDI: _('HEALTH_CHECK.CONFLICTS.NOTE_FILE'),
  IWT_INVOICE: _('IWT_INVOICES.WARNINGS.NO_INVOICE_FILE'),
  IWT_INVOICE_CMR: _('IWT_INVOICES.WARNINGS.NO_CMR_FILE'),
  IWT_INVOICE_CERTIFICATE: _('IWT_INVOICES.WARNINGS.NO_IWT_CERTIFICATE_FILE')
};

const FILE_ACTION_VENDOR_DOCUMENT = [
  {
    ...FileActionsBase.OPEN,
    rights: 'ROLE_CAN_VIEW_VENDOR_INVOICES'
  },
  {
    ...FileActionsBase.DOWNLOAD,
    rights: 'ROLE_CAN_VIEW_VENDOR_INVOICES'
  }
];

const FILE_ACTION_BUYER_INVOICE = [
  {
    ...FileActionsBase.OPEN,
    rights: 'ROLE_CAN_VIEW_BUYER_INVOICES'
  },
  {
    ...FileActionsBase.DOWNLOAD,
    rights: 'ROLE_CAN_VIEW_BUYER_INVOICES'
  }
];

const FILE_ACTION_BUYER_NOTE = [
  {
    ...FileActionsBase.OPEN,
    rights: 'ROLE_CAN_VIEW_BUYER_NOTES'
  },
  {
    ...FileActionsBase.DOWNLOAD,
    rights: 'ROLE_CAN_VIEW_BUYER_NOTES'
  }
];

const FILE_ACTION_VENDOR_INVOICE = [
  ...FILE_ACTION_VENDOR_DOCUMENT,
  {
    ...FileActionsBase.REPLACE,
    rights: 'ROLE_CAN_MANAGE_VENDOR_INVOICES',
    params: { inputLabel: _('INVOICES.ACTIONS.MENU.REPLACE_FILES.INPUT_LABEL') },
    title: _('INVOICES.ACTIONS.MENU.REPLACE_FILES.TITLE')
  },
  {
    ...FileActionsBase.UPLOAD,
    rights: 'ROLE_CAN_MANAGE_VENDOR_INVOICES',
    params: { inputLabel: _('INVOICES.ACTIONS.MENU.UPLOAD_FILES.INPUT_LABEL') },
    title: _('INVOICES.ACTIONS.MENU.UPLOAD_FILES.TITLE')
  }
];

export enum FileTypes {
  VENDOR_INVOICE = 'VENDOR_INVOICE',
  VENDOR_INVOICE_EDI = 'VENDOR_INVOICE_EDI',
  VENDOR_NOTE = 'VENDOR_NOTE',
  VENDOR_NOTE_EDI = 'VENDOR_NOTE_EDI',
  BUYER_INVOICE = 'BUYER_INVOICE',
  BUYER_INVOICE_DRAFT = 'BUYER_INVOICE_DRAFT',
  BUYER_NOTE = 'BUYER_NOTE',
  CONSOLIDATED_BUYER_INVOICE = 'CONSOLIDATED_BUYER_INVOICE',
  CONSOLIDATED_BUYER_NOTE = 'CONSOLIDATED_BUYER_NOTE',
  PAYMENT = 'PAYMENT',
  PAYMENT_REMINDER = 'PAYMENT_REMINDER',
  CORRECTION = 'CORRECTION',
  SHIPPING_LABEL = 'SHIPPING_LABEL',
  IWT_INVOICE = 'IWT_INVOICE',
  IWT_INVOICE_CMR = 'IWT_INVOICE_CMR',
  IWT_INVOICE_CERTIFICATE = 'IWT_INVOICE_CERTIFICATE'
}

export const FileActionItems = {
  [FileTypes.VENDOR_INVOICE]: FILE_ACTION_VENDOR_INVOICE,
  [FileTypes.VENDOR_INVOICE_EDI]: FILE_ACTION_VENDOR_DOCUMENT,
  [FileTypes.VENDOR_NOTE]: [
    ...FILE_ACTION_VENDOR_DOCUMENT,
    {
      ...FileActionsBase.REPLACE,
      rights: 'ROLE_CAN_MANAGE_VENDOR_INVOICES',
      params: { inputLabel: _('VENDOR_NOTES.ACTIONS.REPLACE_FILES.INPUT_LABEL') },
      title: _('VENDOR_NOTES.ACTIONS.REPLACE_FILES.TITLE')
    },
    {
      ...FileActionsBase.UPLOAD,
      rights: 'ROLE_CAN_MANAGE_VENDOR_INVOICES',
      params: { inputLabel: _('VENDOR_NOTES.ACTIONS.UPLOAD_FILES.INPUT_LABEL') },
      title: _('VENDOR_NOTES.ACTIONS.UPLOAD_FILES.TITLE')
    }
  ],
  [FileTypes.VENDOR_NOTE_EDI]: FILE_ACTION_VENDOR_DOCUMENT,
  [FileTypes.BUYER_INVOICE]: FILE_ACTION_BUYER_INVOICE,
  [FileTypes.BUYER_INVOICE_DRAFT]: FILE_ACTION_BUYER_INVOICE,
  [FileTypes.BUYER_NOTE]: FILE_ACTION_BUYER_NOTE,
  [FileTypes.PAYMENT]: [
    {
      ...FileActionsBase.OPEN,
      rights: 'ROLE_CAN_VIEW_PAYMENTS'
    },
    {
      ...FileActionsBase.DOWNLOAD,
      rights: 'ROLE_CAN_VIEW_PAYMENTS'
    }
  ],
  [FileTypes.CONSOLIDATED_BUYER_INVOICE]: FILE_ACTION_BUYER_INVOICE,
  [FileTypes.CONSOLIDATED_BUYER_NOTE]: FILE_ACTION_BUYER_NOTE,
  [FileTypes.PAYMENT_REMINDER]: [
    {
      ...FileActionsBase.OPEN,
      rights: 'ROLE_CAN_VIEW_VENDOR_INVOICES'
    },
    {
      ...FileActionsBase.DOWNLOAD,
      rights: 'ROLE_CAN_VIEW_VENDOR_INVOICES'
    },
    {
      ...FileActionsBase.REPLACE,
      rights: 'ROLE_CAN_MANAGE_VENDOR_INVOICES',
      params: { inputLabel: _('PAYMENT_REMINDERS.ACTIONS.MENU.REPLACE_FILES.INPUT_LABEL') },
      title: _('PAYMENT_REMINDERS.ACTIONS.MENU.REPLACE_FILES.TITLE')
    }
  ],
  [FileTypes.CORRECTION]: [
    {
      ...FileActionsBase.OPEN,
      rights: 'ROLE_CAN_VIEW_BUYER_NOTES'
    },
    {
      ...FileActionsBase.DOWNLOAD,
      rights: 'ROLE_CAN_VIEW_BUYER_NOTES'
    }
  ],
  [FileTypes.IWT_INVOICE]: [
    {
      ...FileActionsBase.OPEN,
      rights: 'ROLE_CAN_VIEW_IWT_INVOICES'
    },
    {
      ...FileActionsBase.DOWNLOAD,
      rights: 'ROLE_CAN_VIEW_IWT_INVOICES'
    }
  ],
  [FileTypes.IWT_INVOICE_CMR]: [
    {
      ...FileActionsBase.OPEN,
      rights: 'ROLE_CAN_VIEW_IWT_INVOICES'
    },
    {
      ...FileActionsBase.DOWNLOAD,
      rights: 'ROLE_CAN_VIEW_IWT_INVOICES'
    },
    {
      ...FileActionsBase.REPLACE,
      rights: 'ROLE_CAN_MANAGE_IWT_INVOICES',
      params: { inputLabel: _('IWT_INVOICES.ACTIONS.REPLACE_CMR_FILES.INPUT_LABEL') },
      title: _('IWT_INVOICES.ACTIONS.REPLACE_CMR_FILES.TITLE')
    },
    {
      ...FileActionsBase.UPLOAD,
      rights: 'ROLE_CAN_MANAGE_IWT_INVOICES',
      params: { inputLabel: _('IWT_INVOICES.ACTIONS.UPLOAD_CMR_FILES.INPUT_LABEL') },
      title: _('IWT_INVOICES.ACTIONS.UPLOAD_CMR_FILES.TITLE')
    },
    {
      ...FileActionsBase.DELETE,
      rights: 'ROLE_CAN_MANAGE_IWT_INVOICES'
    }
  ],
  [FileTypes.IWT_INVOICE_CERTIFICATE]: [
    {
      ...FileActionsBase.OPEN,
      rights: 'ROLE_CAN_VIEW_IWT_INVOICES'
    },
    {
      ...FileActionsBase.DOWNLOAD,
      rights: 'ROLE_CAN_VIEW_IWT_INVOICES'
    }
  ],
  [FileTypes.SHIPPING_LABEL]: [
    {
      ...FileActionsBase.OPEN,
      rights: 'ROLE_CAN_VIEW_RETURN_DETAIL'
    },
    {
      ...FileActionsBase.DOWNLOAD,
      rights: 'ROLE_CAN_VIEW_RETURN_DETAIL'
    }
  ]
};

export const FileExtensions: Record<string, string> = {
  [FileTypes.IWT_INVOICE_CERTIFICATE]: `${allowedFileTypes}, .xlsx, .pst, .txt, .zip`
};
