import { Injectable } from '@angular/core';
import { MmAlertService } from '@mm-ui/core';
import { HttpErrorExtResponse } from '@mm-ui/core/lib/services/api/http-error-ext-response.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ALERT_GLOBAL } from '@fnc-shared/constants/alert-config.constant';
import { COMMON_HIDE_TIME } from '@fnc-shared/constants/alert-hide-time.constant';
import { AlertConfig } from '@fnc-shared/interfaces/alert.interface';
import { HttpResponseMapService } from '@fnc-shared/services/http-response-map.service';
import { MapDataItem, mapDataToParametersFn } from '@fnc-shared/services/interfaces/http-response-map.interface';

@Injectable({ providedIn: 'root' })
export class HttpResponseHandlerService {
  constructor(
    private readonly alertService: MmAlertService,
    private readonly translate: TranslateService,
    private readonly httpMapService: HttpResponseMapService
  ) {}

  successAlert(message: string, data?: object, alertConfig?: AlertConfig) {
    this.pushAlert(message, data, { ...alertConfig, type: 'success' });
  }

  errorAlert(message: string, data?: object, alertConfig?: AlertConfig) {
    this.pushAlert(message, data, { ...alertConfig, type: 'error' });
  }

  infoAlert(message: string, data?: object, alertConfig?: AlertConfig) {
    this.pushAlert(message, data, { ...alertConfig, type: 'info' });
  }

  handleErrorResponse(error: HttpErrorExtResponse, data: object = {}, alertConfig?: AlertConfig) {
    let titles = [];

    if (Array.isArray(error.extData) && error.extData.length) {
      titles = error.extData.map(item => item.message);
    } else {
      titles = [error.extMessage || (error.error && error.error.message) || error.message];
    }

    if (alertConfig?.hideAll) {
      this.hidePreviousAlerts(alertConfig?.stackName);
    }

    titles.forEach(item => this.pushAlert(item, data, { ...alertConfig, type: alertConfig?.type || 'error' }));
  }

  catchError(error: HttpErrorExtResponse, data: object = {}, alertConfig?: AlertConfig) {
    this.handleErrorResponse(error, data, alertConfig);

    return throwError(error);
  }

  prepareErrorMessageText(
    error: HttpErrorExtResponse,
    mapDataToParameters: mapDataToParametersFn,
    mapData: MapDataItem[],
    defaultData: object,
    isMessageIncluded: boolean
  ) {
    const groupForTranslation: Observable<string[]>[] = [];

    if (isMessageIncluded) {
      const message = error.extMessage || (error.error && error.error.message) || error.message;
      if (message) {
        groupForTranslation.push(this.httpMapService.getErrorTranslations([{ ...error, message }], defaultData));
      } else {
        groupForTranslation.push(of([]));
      }
    }

    if (Array.isArray(error.extData) && error.extData.length) {
      const mappedError = mapDataToParameters(error.extData, mapData);
      groupForTranslation.push(this.httpMapService.getErrorTranslations(mappedError, defaultData));
    } else {
      groupForTranslation.push(of([]));
    }

    return combineLatest(groupForTranslation).pipe(map(([mes = [], data = []]) => mes.concat(data)));
  }

  getErrorMessageText(
    error: HttpErrorExtResponse,
    mapDataToParameters: mapDataToParametersFn,
    mapData: MapDataItem[],
    defaultData: object,
    isMessageIncluded: boolean
  ) {
    return this.prepareErrorMessageText(error, mapDataToParameters, mapData, defaultData, isMessageIncluded).pipe(
      map(textBase => textBase.join(' '))
    );
  }

  private pushAlert(message: string, data: object = {}, alertConfig?: AlertConfig) {
    this.alertService.push(alertConfig?.stackName || ALERT_GLOBAL, {
      type: alertConfig?.type || 'success',
      autoHideTime: alertConfig?.autoHideTime || COMMON_HIDE_TIME,
      alertTitle: alertConfig?.isTranslated ? message : this.translate.instant(message, data),
      descriptionTemplate: alertConfig?.descriptionTemplate
    });
  }

  private hidePreviousAlerts(stackName: string = ALERT_GLOBAL) {
    this.alertService.hideAll(stackName);
  }
}
