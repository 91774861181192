import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MmLoaderService } from '@mm-ui/core';
import { RemoveFileOutcome } from '@mm-ui/components/lib/components/uploader/uploader.model';
import { ErrorResponseItem } from '@mm-ui/core/lib/services/api/error-response.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { FileManageService } from '@fnc-shared/components/modal-file-manager-window/file-manager.service';
import {
  allowedFileTypes,
  FileActions,
  maxFileSizeBytes,
  UploadedFile
} from '@fnc-shared/components/modal-file-manager-window/file-manager.constant';
import { FileExtensions, FileTypes } from '@fnc-shared/components/file-tile/file-tile.constant';
import { FormGroupControlsOf, FormSubmitResponse } from '@fnc-app/types/form';

@Component({
  selector: 'fnc-modal-file-manager-window',
  templateUrl: './modal-file-manager-window.component.html',
  styleUrls: ['./modal-file-manager-window.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalFileManagerWindowComponent implements OnInit {
  @Input() fileType: FileTypes;
  @Input() documentId: number;
  @Input() title: string;
  @Input() label: string;
  @Input() filesDroppedExt: FileList;
  filesDropped: FileList;
  form: FormGroup;
  get allowedFileTypes() {
    return FileExtensions[this.fileType as keyof Record<string, string>] ?? allowedFileTypes;
  }
  maxFileSize = maxFileSizeBytes;

  get files() {
    return this.filesValue;
  }

  @Input() set files(files: UploadedFile[]) {
    this.filesValue = files.slice(0, 1);
  }

  private filesValue: UploadedFile[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private readonly formBuilder: FormBuilder,
    private readonly loader: MmLoaderService,
    private readonly fileService: FileManageService
  ) {}

  ngOnInit() {
    this.initForm();
    setTimeout(() => this.filesDroppedExt && this.onFilesDropped(this.filesDroppedExt), 0);
  }

  hide() {
    this.bsModalRef.hide();
  }

  isFileUploaded() {
    return this.form.get('files').value.length > 0;
  }

  onFilesDropped(files: FileList): void {
    this.loader.startGlobal();
    this.filesDropped = files;
  }

  onFilesUploaded() {
    setTimeout(() => this.loader.stopForce(), 0);
  }

  errorOccurred(error: ErrorResponseItem) {
    setTimeout(() => this.loader.stopForce(), 0);
    this.form.get('files').setErrors({ [this.fileService.getFileLoadingErrorKey(error)]: true });
    this.form.get('files').markAsDirty();
  }

  onFilesSelected() {
    this.loader.startGlobal();
  }

  onFileRemove({ filteredFiles }: RemoveFileOutcome) {
    this.form.patchValue({ files: filteredFiles });
  }

  onSubmit({ value }: FormSubmitResponse<FileManagerSubmitModel>) {
    this.manageFiles({ filesToAdd: value.files, filesToRemove: this.files });
  }

  isFormModified() {
    return this.form.dirty;
  }

  canFormBeSubmitted() {
    return this.isFileUploaded() && this.isFormModified();
  }

  private manageFiles({ filesToAdd, filesToRemove }: { filesToAdd: File[]; filesToRemove: UploadedFile[] }) {
    this.loader.startGlobal();
    if (filesToRemove.length) {
      this.fileService
        .replaceFile(this.documentId, filesToAdd, filesToRemove, this.fileType)
        .pipe(
          catchError(err => {
            if (err.fileAction === FileActions.DELETE) {
              this.closeModalWithSuccess();
            }

            if (err.fileAction === FileActions.UPLOAD) {
              this.bsModalRef.hide();
            }

            return throwError(err);
          }),
          finalize(() => this.loader.stopForce())
        )
        .subscribe(() => {
          this.closeModalWithSuccess();
        });

      return;
    }
    this.fileService
      .uploadFiles(this.documentId, filesToAdd, this.fileType)
      .pipe(
        catchError(err => {
          this.bsModalRef.hide();

          return throwError(err);
        }),
        finalize(() => this.loader.stopForce())
      )
      .subscribe(() => {
        this.closeModalWithSuccess();
      });
  }

  private closeModalWithSuccess() {
    this.fileService.fileManagerSuccess.next(true);
    this.bsModalRef.hide();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      files: [this.files]
    } as FormGroupControlsOf<FileManagerFormModel>);
  }
}

interface FileManagerFormModel {
  files: UploadedFile[];
}

interface FileManagerSubmitModel {
  files: File[];
}
