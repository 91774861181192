import { Component } from '@angular/core';
import { SettingsService } from '@fnc-core/services/settings/settings.service';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  selector: 'fnc-date-short-cell',
  template: ` <ng-template [fncTemplateRequestor]="component" let-value="value">
    {{ value | mmDate: settingsService.locale.shortDate : isLocal | mmAddDash }}
  </ng-template>`
})
export class DateShortCellComponent extends CellComponent {
  isLocal = true;

  constructor(public settingsService: SettingsService) {
    super();
  }
}
