export enum VendorPaymentsStatus {
  RETURN_NOTE_REQUIRED = 'RETURN_NOTE_REQUIRED',
  PAYMENTS_BLOCKED = 'PAYMENTS_BLOCKED',
  PAYMENTS_ALLOWED = 'PAYMENTS_ALLOWED'
}

export enum VendorStatus {
  NEW = 'NEW',
  REG_LINK_SENT = 'REGISTRATION_LINK_SENT',
  REGISTRATION_PAUSED = 'REGISTRATION_PAUSED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  REGISTERED = 'REGISTERED',
  PRODUCT_DATA_UPLOAD = 'PRODUCT_DATA_1ST_UPLOAD',
  OFFER_DATA_UPLOAD = 'OFFER_DATA_1ST_UPLOAD',
  LIVE = 'LIVE',
  OFFLINE = 'OFFLINE',
  UNKNOWN = 'UNKNOWN'
}
