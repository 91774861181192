import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { HttpStatus } from '@mm-ui/core';
import { URLFilesDescriptor } from '../file-tile/file-tile.interface';
import { environment } from '@fnc-env/environment';
import { FileTypes } from '@fnc-shared/components/file-tile/file-tile.constant';
import { FileActions } from '@fnc-shared/components/modal-file-manager-window/file-manager.constant';

const VENDOR_INVOICES_API = 'v1/vendor-invoices/:documentId/file';
const NOTES_API = 'v1/notes/:documentId/file';
const PAYMENT_REMINDERS_API = 'v1/payment-reminders/:documentId/file';
const IWT_INVOICES_API = 'v1/iwt-invoices/:documentId';

const UploadErrorMessage = {
  status: HttpStatus.CODE400,
  text: _('COMMON.FILE_UPLOADER.FILE_UPLOAD_ERROR')
};
const DeleteErrorMessage = {
  status: HttpStatus.CODE400,
  text: _('COMMON.FILE_UPLOADER.FILE_DELETE_ERROR')
};

export const URLS: RecordOf<URLFilesDescriptor> = {
  [`${FileActions.UPLOAD}_${FileTypes.VENDOR_INVOICE}`]: {
    url: VENDOR_INVOICES_API,
    messages: [UploadErrorMessage]
  },
  [`${FileActions.DELETE}_${FileTypes.VENDOR_INVOICE}`]: {
    url: `${VENDOR_INVOICES_API}/:fileId`,
    messages: [DeleteErrorMessage]
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.VENDOR_INVOICE}`]: {
    url: VENDOR_INVOICES_API
  },
  [`${FileActions.UPLOAD}_${FileTypes.VENDOR_INVOICE_EDI}`]: {
    url: VENDOR_INVOICES_API,
    messages: [UploadErrorMessage]
  },
  [`${FileActions.DELETE}_${FileTypes.VENDOR_INVOICE_EDI}`]: {
    url: `${VENDOR_INVOICES_API}/:fileId`,
    messages: [DeleteErrorMessage]
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.VENDOR_INVOICE_EDI}`]: {
    url: VENDOR_INVOICES_API
  },
  [`${FileActions.UPLOAD}_${FileTypes.VENDOR_NOTE}`]: {
    url: NOTES_API,
    messages: [UploadErrorMessage]
  },
  [`${FileActions.DELETE}_${FileTypes.VENDOR_NOTE}`]: {
    url: `${NOTES_API}/:fileId`,
    messages: [DeleteErrorMessage]
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.VENDOR_NOTE}`]: {
    url: NOTES_API
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.VENDOR_NOTE_EDI}`]: {
    url: NOTES_API
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.BUYER_NOTE}`]: {
    url: 'v1/buyer-notes/:documentId/file'
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.BUYER_INVOICE}`]: {
    url: 'v1/buyer-invoices/:documentId/file'
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.CONSOLIDATED_BUYER_INVOICE}`]: {
    url: 'v1/buyer-invoices/consolidated/:documentId/file'
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.CONSOLIDATED_BUYER_NOTE}`]: {
    url: 'v1/buyer-notes/consolidated/:documentId/file'
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.BUYER_INVOICE_DRAFT}`]: {
    url: 'v1/buyer-invoices/:documentId/file/draft'
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.PAYMENT}`]: {
    url: 'v1/payment-files/:documentId/file'
  },
  [`${FileActions.UPLOAD}_${FileTypes.PAYMENT_REMINDER}`]: {
    url: PAYMENT_REMINDERS_API,
    messages: [UploadErrorMessage]
  },
  [`${FileActions.DELETE}_${FileTypes.PAYMENT_REMINDER}`]: {
    url: `${PAYMENT_REMINDERS_API}/:fileId`,
    messages: [DeleteErrorMessage]
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.PAYMENT_REMINDER}`]: {
    url: PAYMENT_REMINDERS_API
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.CORRECTION}`]: {
    url: 'v1/buyer-corrections/:documentId/file'
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.IWT_INVOICE}`]: {
    url: `${IWT_INVOICES_API}/file`
  },
  [`${FileActions.UPLOAD}_${FileTypes.IWT_INVOICE_CMR}`]: {
    url: `${IWT_INVOICES_API}/cmr/file`,
    messages: [UploadErrorMessage]
  },
  [`${FileActions.DELETE}_${FileTypes.IWT_INVOICE_CMR}`]: {
    url: `${IWT_INVOICES_API}/cmr/file/:fileId`,
    messages: [DeleteErrorMessage]
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.IWT_INVOICE_CMR}`]: {
    url: `${IWT_INVOICES_API}/cmr/file`
  },
  [`${FileActions.UPLOAD}_${FileTypes.IWT_INVOICE_CERTIFICATE}`]: {
    url: `${IWT_INVOICES_API}/iwt-certification/file`,
    messages: [UploadErrorMessage]
  },
  [`${FileActions.DELETE}_${FileTypes.IWT_INVOICE_CERTIFICATE}`]: {
    url: `${IWT_INVOICES_API}/iwt-certification/file/:fileId`,
    messages: [DeleteErrorMessage]
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.IWT_INVOICE_CERTIFICATE}`]: {
    url: `${IWT_INVOICES_API}/iwt-certification/file`
  },
  [`${FileActions.DOWNLOAD}_${FileTypes.SHIPPING_LABEL}`]: {
    url: 'v1/return/label',
    parameters: {
      useFileName: true,
      previewSuffix: '/inline',
      baseApiPath: environment.shiptrackBasePath
    }
  }
};
