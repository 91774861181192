import { Injectable } from '@angular/core';
import { TreeStatus } from '@mm-ui/components';
import { ApiService } from '@mm-ui/core';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { AuditLogUrls } from './audit-log.url';
import {
  AuditLogChangeSetType,
  AuditLogConfig,
  AuditLogResponse,
  AuditLogTableItem,
  AuditLogValueType
} from '@fnc-shared/models/audit-log';
import { AuditLogApp, AuditLogChangeType, AuditLogEntity, AuditLogStatus } from '@fnc-shared/constants/audit-log.constant';

@Injectable({ providedIn: 'root' })
export class AuditLogService {
  constructor(private readonly apiService: ApiService) {}

  getAuditLogData(entity: number, entityType: AuditLogEntity, application: AuditLogApp, config: AuditLogConfig) {
    const options = {
      entity,
      entityType,
      application
    };

    return this.apiService.get(AuditLogUrls.historyList, null, options).pipe(
      map(data => this.normalizeChanges(data)),
      map(data => this.sortByDate(data)),
      map(data => this.mapAuditLogData(data, config))
    );
  }

  private mapAuditLogData(data: AuditLogResponse[], config: AuditLogConfig) {
    const dataWithMeta: AuditLogResponse[] = data.map(item => ({
      ...item,
      isCreated: this.isCreated(item.context.changeSet),
      changeId: uuid()
    }));
    const cleanData = this.cleanupData(dataWithMeta);
    const mappedData: AuditLogTableItem[] = [];

    cleanData.forEach(item => {
      const changeSet = item.context.changeSet;
      const changeKeys = Object.keys(changeSet);

      if (changeKeys.length === 1) {
        mappedData.push(this.getSimpleRow(item, config));

        return;
      }

      mappedData.push(this.getParentRow(item, config));

      for (const key of changeKeys) {
        mappedData.push(this.getChildRow(item, config, key));
      }
    });

    return mappedData;
  }

  private getChildRow(item: AuditLogResponse, config: AuditLogConfig, propertyName: string) {
    const changeSet = item.context.changeSet;
    const propertyChange = changeSet[propertyName];
    const mapperFn = config.valueMappers?.[propertyName];

    return {
      ...this.getCommonRowProperties(item),
      rowId: uuid(),
      parentId: item.changeId,
      fields: [config.fieldsTranslations?.[propertyName] ?? propertyName],
      previousValue: mapperFn ? mapperFn(propertyChange[0], item, 0) : propertyChange[0],
      newValue: mapperFn ? mapperFn(propertyChange[1], item, 1) : propertyChange[1],
      treeStatus: TreeStatus.DISABLED,
      cellTemplate: config.cellTemplates?.[propertyName]
    };
  }

  private getSimpleRow(item: AuditLogResponse, config: AuditLogConfig) {
    const changeSet = item.context.changeSet;
    const changeKeys = Object.keys(changeSet);
    const key = changeKeys[0];
    const change = changeSet[key];
    const mapperFn = config.valueMappers?.[key];

    return {
      ...this.getCommonRowProperties(item),
      rowId: item.changeId,
      fields: this.getChangeTranslations(changeKeys, config),
      previousValue: mapperFn ? mapperFn(change[0], item, 0) : change[0],
      newValue: mapperFn ? mapperFn(change[1], item, 1) : change[1],
      treeStatus: TreeStatus.DISABLED,
      cellTemplate: config.cellTemplates?.[key]
    };
  }

  private getParentRow(item: AuditLogResponse, config: AuditLogConfig) {
    const changeSet = item.context.changeSet;
    const changeKeys = Object.keys(changeSet);

    return {
      ...this.getCommonRowProperties(item),
      rowId: item.changeId,
      fields: this.getChangeTranslations(changeKeys, config),
      previousValue: Object.keys(changeSet).map(key => changeSet[key][0]),
      newValue: Object.keys(changeSet).map(key => changeSet[key][1]),
      treeStatus: TreeStatus.COLLAPSED
    };
  }

  private getCommonRowProperties(item: AuditLogResponse) {
    const event = item.isCreated ? AuditLogStatus.CREATED : AuditLogStatus.UPDATED;
    const user = item.userName === 'cli' ? 'COMMON.AUDIT_LOG.CLI_LABEL' : item.userName;
    const dateTime = new Date(item.eventDate);

    return {
      user,
      dateTime,
      event
    };
  }

  private cleanupData(data: AuditLogResponse[]) {
    const clearData: AuditLogResponse[] = [];

    data.forEach(item => {
      const changeSet = item.context.changeSet;
      const clearChangeSet: RecordOf<AuditLogChangeSetType> = {};

      for (const key of Object.keys(changeSet)) {
        const change = changeSet[key];
        const changeType = this.getChangeType(change);

        const { comparisonPrevValue, comparisonNewValue } = this.getComparisonValues(change, changeType);

        const isTrueChange =
          (changeType === AuditLogChangeType.OBJECT && !isEqual(comparisonNewValue, comparisonPrevValue)) ||
          comparisonNewValue !== comparisonPrevValue;
        const isChangePresent = !this.isEmptyChange(change[0]) && !this.isEmptyChange(change[1]);

        if (isTrueChange && isChangePresent) {
          clearChangeSet[key] = change;
        }
      }

      if (Object.keys(clearChangeSet).length > 0) {
        clearData.push({
          ...item,
          context: {
            changeSet: clearChangeSet
          }
        });
      }
    });

    return clearData;
  }

  private getChangeTranslations(changeKeys: string[], config: AuditLogConfig) {
    return changeKeys.map(changeKey => {
      const translation = config.fieldsTranslations?.[changeKey];

      return translation ?? changeKey;
    });
  }

  private isEmptyChange(changeValue: AuditLogValueType) {
    if (Array.isArray(changeValue) || typeof changeValue === 'string') {
      return changeValue.length === 0;
    }

    if (changeValue !== null && typeof changeValue === 'object') {
      return Object.keys(changeValue).length === 0;
    }

    return changeValue === undefined;
  }

  private isCreated(changeSet: RecordOf<AuditLogChangeSetType>) {
    const keys = Object.keys(changeSet);
    const prevValues = [];

    for (const key of keys) {
      if (changeSet[key].length) {
        prevValues.push(changeSet[key][0]);
      }
    }

    return prevValues.length > 0 && prevValues.every(value => value === null);
  }

  private normalizeChanges(changes: AuditLogResponse[]) {
    const normalizedChanges: AuditLogResponse[] = [];

    for (const change of changes) {
      const changeSet = change.context.changeSet;
      const normalizedChange: AuditLogResponse = {
        ...change,
        context: {
          changeSet: {}
        }
      };

      for (const property in changeSet) {
        if (Object.prototype.hasOwnProperty.call(changeSet, property)) {
          const normalChangeProperty = this.normalizeChangeProperty(changeSet[property]);

          if (normalChangeProperty) {
            normalizedChange.context.changeSet[property] = normalChangeProperty;
          }
        }
      }

      normalizedChanges.push(normalizedChange);
    }

    return normalizedChanges;
  }

  private normalizeChangeProperty(change: AuditLogChangeSetType) {
    if (Array.isArray(change)) {
      if (change.length === 0) {
        return null;
      }

      return change;
    }

    if (isNil(change[1])) {
      return null;
    }

    return [null, change[1]];
  }

  private sortByDate(data: AuditLogResponse[]) {
    return data.sort((changeSet1, changeSet2) => new Date(changeSet2.eventDate).getTime() - new Date(changeSet1.eventDate).getTime());
  }

  private getComparisonValues(change: AuditLogChangeSetType, changeType: AuditLogChangeType) {
    let comparisonPrevValue: AuditLogValueType;
    let comparisonNewValue: AuditLogValueType;
    const dateKey = 'date';

    switch (changeType) {
      case AuditLogChangeType.NUMBER:
        comparisonPrevValue = parseFloat(change[0] as string);
        comparisonNewValue = parseFloat(change[1] as string);
        break;
      case AuditLogChangeType.DATE:
        comparisonPrevValue = (change[0] as RecordExtPrimitive)?.[dateKey] as AuditLogValueType;
        comparisonNewValue = (change[0] as RecordExtPrimitive)?.[dateKey] as AuditLogValueType;
        break;
      default:
        comparisonPrevValue = change[0];
        comparisonNewValue = change[1];
        break;
    }

    return {
      comparisonPrevValue,
      comparisonNewValue
    };
  }

  private getChangeType(change: AuditLogChangeSetType) {
    const isNumberFn = (value: AuditLogValueType) => typeof value === 'number';
    const isDateFn = (value: AuditLogValueType) => value !== null && typeof value === 'object' && !!value?.date;
    const isObjectFn = (value: AuditLogValueType) => value !== null && typeof value === 'object';

    if (change.some(isNumberFn)) {
      return AuditLogChangeType.NUMBER;
    }

    if (change.some(isDateFn)) {
      return AuditLogChangeType.DATE;
    }

    if (change.some(isObjectFn)) {
      return AuditLogChangeType.OBJECT;
    }

    return AuditLogChangeType.PRIMITIVE;
  }
}
