import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { SettingsSideMenuItems } from '@fnc-core/components/settings-page/settings.constant';

@UntilDestroy()
@Component({
  selector: 'fnc-settings-page',
  templateUrl: './settings-page.component.html'
})
export class SettingsPageComponent extends AccountingPageComponent {
  sideMenu = SettingsSideMenuItems;
}
