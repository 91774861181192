import { FilterTypes } from './filter-types.constant';
import { FilterNames } from '@fnc-shared/models/filter-names';

export const arrayFiltersMap = {
  [FilterNames.ACCESS]: FilterTypes.ARRAY,
  [FilterNames.ACCOUNTING_PERIOD_NUMBER]: FilterTypes.ARRAY,
  [FilterNames.ACTIVE]: FilterTypes.ARRAY,
  [FilterNames.ASSORTMENT_NEW]: FilterTypes.ARRAY,
  [FilterNames.AVAILABILITY]: FilterTypes.ARRAY,
  [FilterNames.BRAND]: FilterTypes.ARRAY,
  [FilterNames.BRAND_IDS]: FilterTypes.ARRAY,
  [FilterNames.BUYER_TYPE]: FilterTypes.ARRAY,
  [FilterNames.COUNTRY]: FilterTypes.ARRAY,
  [FilterNames.DELIVERY_TYPE]: FilterTypes.ARRAY,
  [FilterNames.DESTINATION_COUNTRY]: FilterTypes.ARRAY,
  [FilterNames.ERROR_TYPE]: FilterTypes.ARRAY,
  [FilterNames.E_INVOICE_STATUS]: FilterTypes.ARRAY,
  [FilterNames.EXPECTED_CREDIT_NOTE_STATUS]: FilterTypes.ARRAY,
  [FilterNames.FROM_ID]: FilterTypes.ARRAY,
  [FilterNames.FULFILLMENT_ORDER_TYPE]: FilterTypes.ARRAY,
  [FilterNames.LABELS]: FilterTypes.ARRAY,
  [FilterNames.ID]: FilterTypes.ARRAY,
  [FilterNames.INTENTIONS]: FilterTypes.ARRAY,
  [FilterNames.INVENTORY_IDS]: FilterTypes.ARRAY,
  [FilterNames.INVENTORY_TO]: FilterTypes.ARRAY,
  [FilterNames.INVENTORY_TYPE]: FilterTypes.ARRAY,
  [FilterNames.INVOICE_GENERATION_STATUS]: FilterTypes.ARRAY,
  [FilterNames.INVOICE_TYPE]: FilterTypes.ARRAY,
  [FilterNames.ITEM_CATEGORY_IDS]: FilterTypes.ARRAY,
  [FilterNames.MESSAGE_DIRECTION]: FilterTypes.ARRAY,
  [FilterNames.NOTE_REASON]: FilterTypes.ARRAY,
  [FilterNames.OWN_BRAND]: FilterTypes.ARRAY,
  [FilterNames.PAYMENTS_SETTINGS]: FilterTypes.ARRAY,
  [FilterNames.PAYMENT_SYSTEM]: FilterTypes.ARRAY,
  [FilterNames.PRODUCT_CATEGORY]: FilterTypes.ARRAY,
  [FilterNames.REGION]: FilterTypes.ARRAY,
  [FilterNames.REPLENISHMENT_CATEGORIES]: FilterTypes.ARRAY,
  [FilterNames.SALES_CHANNEL]: FilterTypes.ARRAY,
  [FilterNames.SALE_PLATFORM]: FilterTypes.ARRAY,
  [FilterNames.SALE_COUNTRY]: FilterTypes.ARRAY,
  [FilterNames.SERVICE_LEVELS]: FilterTypes.ARRAY,
  [FilterNames.SERVICE_LEVEL_CLASS]: FilterTypes.ARRAY,
  [FilterNames.SOURCE]: FilterTypes.ARRAY,
  [FilterNames.SOURCE_COUNTRY]: FilterTypes.ARRAY,
  [FilterNames.SOURCE_TYPE]: FilterTypes.ARRAY,
  [FilterNames.STATUS]: FilterTypes.ARRAY,
  [FilterNames.TO_ID]: FilterTypes.ARRAY,
  [FilterNames.TYPE]: FilterTypes.ARRAY,
  [FilterNames.USERS]: FilterTypes.ARRAY,
  [FilterNames.VAT_CODE]: FilterTypes.ARRAY,
  [FilterNames.VENDOR_ID]: FilterTypes.ARRAY,
  [FilterNames.VENDOR_TYPE]: FilterTypes.ARRAY,
  [FilterNames.NOTE_TYPE]: FilterTypes.ARRAY
};
