<div class="fnc-vendor-status-chip">
  <span
    mmTag
    placement="top"
    [tooltip]="'COMMON.VENDOR.STATUSES.TOOLTIPS.' + status | translate"
    [color]="backgroundColor"
    [textColor]="textColor"
  >
    {{ "COMMON.VENDOR.STATUSES.LABELS." + status | translate }}
  </span>
</div>
