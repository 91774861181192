// TODO: Reports module is added as a dependency of notifications message. all relative messages should be moved to Notification module.
export const TranslationCoreModules = [
  'ALERT_CONFIRMATION',
  'COMMON',
  'DOCUMENT_FORM',
  'NAVIGATION',
  'NOTIFICATIONS',
  'REPORTS',
  'VALIDATION'
];

export const TranslationFinanceModules = [
  ...TranslationCoreModules,
  'ACCOUNTS_RECEIVABLE',
  'ACCOUNT_REPORTS',
  'BUYER_INVOICES',
  'BUYER_NOTES',
  'CLEARING_OFFICE',
  'DELIVERIES',
  'DOCUMENTS',
  'EDIT_ADDRESS_MODAL',
  'FINANCE_OFFICE',
  'FINANCE_COMMON',
  'GR',
  'HEALTH_CHECK',
  'INVOICES',
  'INVOICE_UNIT_MODAL',
  'IWT_INVOICES',
  'MATCHING_MODAL',
  'MATCHING_VIEW',
  'ORDERS',
  'ORDER_UNIT_MODAL',
  'PAYMENTS',
  'PAYMENT_FILE',
  'PAYMENT_REMINDERS',
  'SALE_ORDERS',
  'VENDORS',
  'VENDOR_BALANCES',
  'VENDOR_DETAILS',
  'VENDOR_NOTES'
];

export const TranslationOfferManagementModules = [...TranslationCoreModules, 'INVENTORY_UNITS', 'OFFER_MANAGEMENT'];

export const TranslationReportsModules = [...TranslationCoreModules, 'REPORTS', 'INTRASTAT', 'INVOICES'];

export const TranslationSettingsModules = [...TranslationCoreModules, 'ACCOUNTING_PERIODS', 'VENDORS_CONTROL_PANEL', 'VENDORS'];

export const TranslationSupplyChainModules = [...TranslationCoreModules, 'SUPPLY_CHAIN'];

export const TranslationForbiddenModules = ['FORBIDDEN_PAGE'];

export const TranslationInstallationServicesModules = [...TranslationCoreModules, 'INSTALLATION_SERVICES'];

export const TranslationFulfilmentModules = [
  ...TranslationCoreModules,
  'FULFILMENT',
  'SALE_ORDERS',
  'VENDORS',
  'BUYER_INVOICES',
  'BUYER_NOTES'
];

export const TranslationAdminPortalModules = ['FAILED_MESSAGES', ...TranslationCoreModules];

export const TranslationProductDataModules = [...TranslationCoreModules, 'ATTRIBUTE'];
