import { Component, Input } from '@angular/core';
import { VendorStatusBgColors, VendorStatusTextColors } from './vendor-status-chip.constants';
import { VendorStatus } from '@fnc-app/modules/core/services/vendors/state/vendor.constants';

@Component({
  selector: 'fnc-vendor-status-chip',
  templateUrl: './vendor-status-chip.component.html',
  styleUrls: ['./vendor-status-chip.component.scss']
})
export class VendorStatusChipComponent {
  @Input() status: VendorStatus;

  get backgroundColor() {
    return VendorStatusBgColors[this.status] ?? VendorStatusBgColors.DEFAULT;
  }

  get textColor() {
    return VendorStatusTextColors[this.status] ?? VendorStatusTextColors.DEFAULT;
  }
}
