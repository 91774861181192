<ng-template #modalTemplate>
  <mm-modal compact class="fnc-plc-status-modal">
    <mm-modal-header>
      <mm-bar>
        <mm-bar-item>
          <p class="text-overflow" data-cy="plc-status-modal-header">
            {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TITLE" | translate }}
            <mm-icon kind="help-filled" color="blue" size="0.6" [tooltip]="plcToolTipTemplate" placement="top"></mm-icon>
            <span class="plc-status-attributes">
              <span class="plc-status-attributes--key">
                ({{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.GTIN" | translate }}:
                <span class="plc-status-attributes--value">{{ rowData.gtin }}</span
                >,
              </span>
              <span class="plc-status-attributes--key">
                {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.SKU" | translate }}:
                <span class="plc-status-attributes--value">
                  {{ rowData.sku }}
                  <span class="plc-status-attributes--key">)</span>
                </span>
              </span>
            </span>
          </p>
        </mm-bar-item>
        <mm-bar-item pushRight>
          <mm-link-icon kind="close" (click)="closeModal()"></mm-link-icon>
        </mm-bar-item>
      </mm-bar>
    </mm-modal-header>

    <mm-modal-content [isPadded]="false">
      <mm-panel class="fnc-plc-status-tabgroup">
        <mm-tabgroup [activeTab]="activeTab" [isSticky]="true" (activeTabChange$)="activeTabChange($event)">
          <ng-container *ngFor="let country of tabsData; index as i">
            <mm-tab
              [label]="country.label"
              [name]="country.name"
              [iconKind]="country.iconKind"
              [iconColor]="country.iconColor"
              iconSize="0.5"
              [flagKind]="country.name"
              flagSize="0.4"
            ></mm-tab>
          </ng-container>
        </mm-tabgroup>
        <mm-panel-content>
          <mm-tabgroup-content [activeTab]="activeTab">
            <mm-tab-content [name]="activeTab" [activeTab]="activeTab">
              <ng-container *ngFor="let section of plcData[activeTab]; index as i; last as last">
                <div
                  class="m-row status-detail-container"
                  id="section_{{ i }}"
                  [attr.data-cy]="'status-detail-container__' + i"
                  [ngClass]="last ? 'container-last' : ''"
                >
                  <div class="m-col-xs12of12 status-detail-container__section">
                    <div class="m-col-xs3of12 m-col-centered status-detail-container__section-platform">
                      <ng-container *ngIf="displaySalesChannelIcon(section.platform); else sectionPlatformName">
                        <div class="status-detail-container__section-details-platform-icon">
                          <mm-icon
                            [kind]="getSalesChannelIcon(section.platform)"
                            color="metro-blue"
                            [attr.aria-label]="section.platform"
                          ></mm-icon>
                        </div>
                      </ng-container>
                      <ng-template #sectionPlatformName>
                        <span class="status-detail-container__section-details-platform-name">{{ section.platform }}</span>
                      </ng-template>
                    </div>
                    <div class="m-col-xs9of12">
                      <div class="m-row">
                        <div class="m-col-xs12of12 status-detail-container__section">
                          <ng-container
                            *ngFor="let intention of [section.currentIntention, section.nextIntention]; let intentionIndex = index"
                          >
                            <div
                              *ngIf="intention"
                              class="m-col-xs6of12"
                              [attr.data-cy]="'status-detail-container__section__container_' + intentionIndex"
                            >
                              <div [attr.data-cy]="intentionIndex === 0 ? 'INTENTION_NOW' : 'INTENTION_FUTURE'">
                                <span class="status-detail-container__section-details-labelIntention">{{
                                  intentionIndex === 0 ? "NOW" : "FUTURE"
                                }}</span>
                              </div>
                              <div
                                class="m-col-xs12of12 status-detail-container__section-details"
                                id="status-detail-container-section-details-{{ intentionIndex }}"
                                [attr.data-cy]="'section-details-intention_' + intentionIndex"
                              >
                                <span
                                  [attr.data-cy]="'section-details-intention-status'"
                                  [color]="getStatusIconColor(intention.status)"
                                  [textColor]="getTagTextColor()"
                                  [tooltip]="getToolTipStatus(intention.status)"
                                  placement="right"
                                  mmTag
                                >
                                  {{ intention.status }}
                                </span>
                                <div class="status-detail-container__section-details__textContainer">
                                  <span class="status-detail-container__section-details__textContainer-label">{{
                                    "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.REASON" | translate
                                  }}</span>
                                  <span
                                    class="status-detail-container__section-details__textContainer-labeltext max-width"
                                    [attr.data-cy]="'section-details-intention-motive'"
                                    [title]="getMotiveLabel(intention.motive)"
                                    >{{ getMotiveLabel(intention.motive) | mmAddDash }}</span
                                  >
                                </div>
                                <div class="status-detail-container__section-details__textContainer">
                                  <span class="status-detail-container__section-details__textContainer-label">{{
                                    "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.COMMENT" | translate
                                  }}</span>
                                  <span
                                    class="status-detail-container__section-details__textContainer-labeltext max-width"
                                    [attr.data-cy]="'section-details-intention-comment'"
                                    [title]="intention.comment"
                                    >{{ intention.comment | mmAddDash }}</span
                                  >
                                </div>
                                <div class="status-detail-container__section-details__textContainer">
                                  <span class="status-detail-container__section-details__textContainer-label">{{
                                    "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.VALID_FROM" | translate
                                  }}</span>
                                  <span
                                    class="status-detail-container__section-details__textContainer-labeltext"
                                    [attr.data-cy]="'section-details-intention-dueAt'"
                                    >{{ intention.dueAt | mmDate: settingsService.locale.shortDate }}</span
                                  >
                                </div>
                                <div class="status-detail-container__section-details__textContainer" *ngIf="intention.changedBy">
                                  <span class="status-detail-container__section-details__textContainer-label">{{
                                    "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.CHANGED_BY" | translate
                                  }}</span>
                                  <span
                                    class="status-detail-container__section-details__textContainer-labeltext"
                                    [attr.data-cy]="'section-details-intention-changedBy'"
                                    >{{ intention.changedBy | mmAddDash }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </mm-tab-content>
          </mm-tabgroup-content>
        </mm-panel-content>
      </mm-panel>
    </mm-modal-content>
  </mm-modal>
</ng-template>

<ng-template #plcToolTipTemplate>
  <div class="toolTip_container">
    <div class="toolTip_container--text text-header">
      {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TOOLTIP_POPUP.HEADER" | translate }}
    </div>
    <div class="toolTip_container--text text-header">
      {{ "SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TOOLTIP_POPUP.HEADER_STATUS" | translate }}
    </div>
  </div>
</ng-template>
