import { Injectable } from '@angular/core';
import { MmLoaderService } from '@mm-ui/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { UiLoaderQuery } from '@fnc-core/services/ui/state/ui-loader.query';
import { UiLoaderStore } from '@fnc-core/services/ui/state/ui-loader.store';
import { UiLoaderType } from '@fnc-core/services/ui/state/ui.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UiService {
  set loading(global: boolean) {
    this.updateLoaderState(UiLoaderType.GLOBAL, global);
  }

  set loadingPage(mainPage: boolean) {
    this.updateLoaderState(UiLoaderType.MAIN_PAGE, mainPage);
  }

  set loadingList(mainList: boolean) {
    this.updateLoaderState(UiLoaderType.MAIN_LIST, mainList);
  }

  constructor(
    protected loaderService: MmLoaderService,
    protected uiLoaderStore: UiLoaderStore,
    protected uiLoaderQuery: UiLoaderQuery
  ) {
    this.subscribeOnGlobalLoader();
  }

  protected subscribeOnGlobalLoader() {
    this.uiLoaderQuery.global$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          if (value) {
            this.loaderService.stopForce();
            this.loaderService.startGlobal();
          } else {
            this.loaderService.stop();
          }
        })
      )
      .subscribe();
  }

  protected updateLoaderState(type: UiLoaderType, value: boolean) {
    const prevValue = this.uiLoaderQuery.getValue()?.[type] ?? 0;
    this.uiLoaderStore.update({ [type]: value ? prevValue + 1 : prevValue - 1 });
  }
}
