import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '@mm-ui/core';
import { DefaultNavigateComponent } from '@fnc-core/components/default-navigate/default-navigate.component';
import { ReportsSideMenuItems } from '@fnc-core/components/reports-page/reports-side-menu-items.constant';

@Component({
  selector: 'fnc-dashboard-reports',
  template: '<div></div>'
})
export class DashboardReportsComponent extends DefaultNavigateComponent {
  protected sideMenuItems = ReportsSideMenuItems;

  constructor(router: Router, permissions: PermissionsService) {
    super(router, permissions);
  }
}
