import { Component } from '@angular/core';
import { SettingsService } from '@fnc-core/services/settings/settings.service';
import { CellComponent } from '@fnc-shared/components/cells/cell.component';

@Component({
  selector: 'fnc-date-month-cell',
  template: ` <ng-template [fncTemplateRequestor]="component" let-value="value">
    {{ value | mmDate: monthDate : isLocal | mmAddDash }}
  </ng-template>`
})
export class DateMonthCellComponent extends CellComponent {
  isLocal = true;
  monthDate: string;

  constructor(public settingsService: SettingsService) {
    super();
    this.monthDate = this.settingsService.locale.shortMonthYear;
  }
}
