import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { TreeStatus } from '@mm-ui/components';
import { Cell } from '@fnc-shared/components/cells/cell';

@Component({
  template: `
    <ng-template [fncTemplateRequestor]="component" let-context="cellContext">
      <ng-container *ngIf="context.row.treeStatus !== treeStatus.DISABLED">
        <button
          class="btn-tree"
          *ngIf="context.row.treeStatus === treeStatus.COLLAPSED"
          (click)="context.onTreeAction()"
          [disabled]="context.row.isExpandDisabled"
        >
          <mm-icon kind="chevron-right-small" [size]="0.7"></mm-icon>
        </button>
        <button
          class="btn-tree"
          *ngIf="context.row.treeStatus === treeStatus.EXPANDED"
          (click)="context.onTreeAction()"
          [disabled]="context.row.isExpandDisabled"
        >
          <mm-icon kind="chevron-down-small" [size]="0.7"></mm-icon>
        </button>
        <button class="btn-tree" *ngIf="context.row.treeStatus === treeStatus.LOADING" disabled (click)="context.onTreeAction()">
          <mm-spinner [size]="0.5"></mm-spinner>
        </button>
      </ng-container>
    </ng-template>
  `,
  styleUrls: ['./btn-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BtnTreeComponent implements Cell {
  treeStatus = TreeStatus;
  templateRef: TemplateRef<ImplicitAny>;
  component = this;
}
