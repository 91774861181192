import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '@mm-ui/core';
import { AdminSideMenuItems } from '@fnc-core/components/admin-page/admin-side-menu-items.constant';
import { DefaultNavigateComponent } from '@fnc-core/components/default-navigate/default-navigate.component';

@Component({
  selector: 'fnc-dashboard-admin',
  template: '<div></div>'
})
export class DashboardAdminComponent extends DefaultNavigateComponent {
  protected sideMenuItems = AdminSideMenuItems;

  constructor(router: Router, permissions: PermissionsService) {
    super(router, permissions);
  }
}
