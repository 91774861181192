import { Route } from '@angular/router';
import { DictionaryResolverService, PermissionsGuard } from '@mm-ui/core';
import { TranslationFinanceModules } from '@fnc-app/routes/translation-modules';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { DashboardAccountingComponent } from '@fnc-core/components/dashboard-accounting/dashboard-accounting.component';
import { InitGuard } from '@fnc-core/guards/init.guard';
import { Dictionary } from '@fnc-shared/constants/dictionary.constant';
import { Path } from '@fnc-shared/constants/path.constant';

const GRTitle = 'NAVIGATION.ACCOUNTING.GOOD_RECEIPTS';

export const FinancePortalRoute: Route = {
  path: Path.financePortal,
  canActivate: [InitGuard],
  component: AccountingPageComponent,
  data: {
    breadcrumb: 'NAVIGATION.ACCOUNTING.TITLE',
    i18n: TranslationFinanceModules
  },
  children: [
    {
      path: '',
      component: DashboardAccountingComponent,
      canActivate: [PermissionsGuard],
      data: {
        permissions: {
          rule: {
            $or: [
              'ROLE_CAN_VIEW_VENDOR_INVOICES',
              'ROLE_CAN_EXPORT_PAYMENTS',
              'ROLE_CAN_VIEW_VENDOR_BALANCES',
              'ROLE_CAN_VIEW_PAYMENTS',
              'ROLE_CAN_VIEW_GR_HEADERS',
              'ROLE_CAN_VIEW_FORDERS',
              'ROLE_CAN_VIEW_SALE_ORDERS',
              'ROLE_CAN_VIEW_BUYER_NOTES',
              'ROLE_CAN_VIEW_BUYER_INVOICES'
            ]
          },
          navigateTo: ['/', Path.forbidden]
        }
      }
    },
    {
      path: Path.vendorNotes,
      loadChildren: () => import('@fnc-modules/vendor-notes/vendor-notes.module').then(m => m.VendorNotesModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.NOTES',
        title: 'NAVIGATION.ACCOUNTING.NOTES',
        permissions: { rule: 'ROLE_CAN_VIEW_VENDOR_INVOICES', navigateTo: ['/', Path.forbidden] },
        hasInnerPanel: true,
        dictionaries: [Dictionary.COUNTRIES, Dictionary.VENDOR_TYPES]
      }
    },
    {
      path: Path.fulfilmentOrders,
      loadChildren: () => import('../modules/fulfilment-orders/orders.module').then(m => m.FulfilmentOrdersModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.FULFILMENT_ORDERS',
        title: 'NAVIGATION.ACCOUNTING.FULFILMENT_ORDERS',
        permissions: { rule: 'ROLE_CAN_VIEW_FORDERS' },
        navigateTo: ['/', Path.forbidden],
        dictionaries: [
          Dictionary.VENDOR_TYPES,
          Dictionary.SALES_CHANNELS,
          Dictionary.FULFILLMENT_ORDER_TYPES,
          Dictionary.FULFILLMENT_SOURCE_COUNTRIES
        ]
      }
    },
    {
      path: Path.saleOrders,
      loadChildren: () => import('../modules/sale-orders/orders.module').then(m => m.SaleOrdersModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.SALE_ORDERS',
        title: 'NAVIGATION.ACCOUNTING.SALE_ORDERS',
        permissions: { rule: 'ROLE_CAN_VIEW_SALE_ORDERS', navigateTo: ['/', Path.forbidden] },
        dictionaries: [Dictionary.SALES_PLATFORMS, Dictionary.SALES_COUNTRIES]
      }
    },
    {
      path: Path.vendorInvoices,
      loadChildren: () => import('../modules/vendor-invoices/vendor-invoices.module').then(m => m.VendorInvoicesModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.VENDOR_INVOICES',
        title: 'NAVIGATION.ACCOUNTING.VENDOR_INVOICES',
        permissions: { rule: 'ROLE_CAN_VIEW_VENDOR_INVOICES', navigateTo: ['/', Path.forbidden] },
        hasInnerPanel: true,
        dictionaries: [Dictionary.COUNTRIES, Dictionary.VENDOR_TYPES]
      }
    },
    {
      path: Path.financeOffice,
      loadChildren: () => import('../modules/finance-office/finance-office.module').then(m => m.FinanceOfficeModule),
      canActivate: [PermissionsGuard],
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.FINANCE_OFFICE',
        title: 'NAVIGATION.ACCOUNTING.FINANCE_OFFICE',
        permissions: { rule: 'ROLE_CAN_EXPORT_PAYMENTS', navigateTo: ['/', Path.forbidden] }
      }
    },
    {
      path: Path.vendorBalances,
      loadChildren: () => import('../modules/vendor-balances/vendor-balances.module').then(m => m.VendorBalancesModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.VENDOR_BALANCES',
        title: 'NAVIGATION.ACCOUNTING.VENDOR_BALANCES',
        permissions: { rule: 'ROLE_CAN_VIEW_VENDOR_BALANCES', navigateTo: ['/', Path.forbidden] },
        dictionaries: [Dictionary.PAYMENT_SYSTEM]
      }
    },
    {
      path: Path.goodReceiptsOld,
      loadChildren: () => import('../modules/gr-old/gr-old.module').then(m => m.GrOldModule),
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.GOOD_RECEIPTS_OLD',
        title: GRTitle,
        permissions: { rule: 'ROLE_CAN_VIEW_GR_HEADERS', navigateTo: ['/', Path.forbidden] }
      }
    },
    {
      path: Path.goodsReceipts,
      loadChildren: () => import('../modules/gr/gr.module').then(m => m.GrModule),
      data: {
        breadcrumb: GRTitle,
        title: GRTitle,
        permissions: { rule: 'ROLE_CAN_VIEW_GR_HEADERS', navigateTo: ['/', Path.forbidden] },
        dictionaries: [Dictionary.DELIVERY_STATUSES, Dictionary.DELIVERY_TYPES]
      },
      resolve: {
        dictionaries: DictionaryResolverService
      }
    },
    {
      path: Path.payments,
      loadChildren: () => import('../modules/payments/payments.module').then(m => m.PaymentsModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.PAYMENTS',
        title: 'NAVIGATION.ACCOUNTING.PAYMENTS',
        permissions: { rule: 'ROLE_CAN_VIEW_PAYMENTS', navigateTo: ['/', Path.forbidden] },
        dictionaries: [Dictionary.PAYMENT_SYSTEM]
      }
    },
    {
      path: Path.buyerNotes,
      loadChildren: () => import('../modules/buyer-notes/buyer-notes.module').then(m => m.BuyerNotesModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.BUYER_NOTES',
        title: 'NAVIGATION.ACCOUNTING.BUYER_NOTES',
        permissions: { rule: 'ROLE_CAN_VIEW_BUYER_NOTES', navigateTo: ['/', Path.forbidden] },
        hasInnerPanel: true,
        dictionaries: [Dictionary.SALES_PLATFORMS, Dictionary.SALES_COUNTRIES, Dictionary.BUYER_TYPES, Dictionary.BUYER_INVOICE_STATUSES]
      }
    },
    {
      path: Path.clearingOffice,
      loadChildren: () => import('../modules/clearing-office/clearing-office.module').then(m => m.ClearingOfficeModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.CLEARING_OFFICE',
        title: 'NAVIGATION.ACCOUNTING.CLEARING_OFFICE',
        permissions: { rule: 'ROLE_CAN_VIEW_VENDOR_INVOICES', navigateTo: ['/', Path.forbidden] },
        dictionaries: Dictionary.VENDOR_TYPES
      }
    },
    {
      path: Path.buyerInvoices,
      loadChildren: () => import('../modules/buyer-invoices/buyer-invoices.module').then(m => m.BuyerInvoicesModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.BUYER_INVOICES',
        title: 'NAVIGATION.ACCOUNTING.BUYER_INVOICES',
        permissions: { rule: 'ROLE_CAN_VIEW_BUYER_INVOICES', navigateTo: ['/', Path.forbidden] },
        hasInnerPanel: true,
        dictionaries: [Dictionary.SALES_PLATFORMS, Dictionary.SALES_COUNTRIES, Dictionary.BUYER_TYPES, Dictionary.BUYER_INVOICE_STATUSES]
      }
    },
    {
      path: Path.paymentReminders,
      loadChildren: () => import('../modules/payment-reminders/payment-reminders.module').then(m => m.PaymentRemindersModule),
      canActivate: [PermissionsGuard],
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.PAYMENT_REMINDERS',
        title: 'NAVIGATION.ACCOUNTING.PAYMENT_REMINDERS',
        permissions: {
          rule: ['ROLE_CAN_VIEW_VENDOR_INVOICES', 'FF_FP_PAYMENT_REMINDERS'],
          navigateTo: ['/', Path.forbidden]
        },
        hasInnerPanel: true
      }
    },
    {
      path: Path.accountsReceivable,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/accounts-receivable/accounts-receivable.module').then(m => m.AccountsReceivableModule),
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.ACCOUNTS_RECEIVABLE',
        title: 'NAVIGATION.ACCOUNTING.ACCOUNTS_RECEIVABLE',
        hasInnerPanel: true,
        // TODO: add new permission
        permissions: {
          rule: ['ROLE_CAN_VIEW_ACCOUNTS_PAYABLE'],
          navigateTo: ['/', Path.forbidden]
        },
        dictionaries: [Dictionary.VAT_CODES]
      }
    },
    {
      path: Path.accountReports,
      canActivate: [PermissionsGuard],
      loadChildren: () => import('../modules/account-reports/account-reports.module').then(m => m.AccountsPayableModule),
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.ACCOUNT_REPORTS',
        title: 'NAVIGATION.ACCOUNTING.ACCOUNT_REPORTS',
        permissions: {
          rule: ['ROLE_CAN_VIEW_ACCOUNTS_PAYABLE'],
          navigateTo: ['/', Path.forbidden]
        },
        dictionaries: [Dictionary.VAT_CODES, Dictionary.VENDOR_TYPES]
      }
    },
    {
      path: Path.deliveries,
      loadChildren: () => import('../modules/deliveries/deliveries.module').then(m => m.DeliveriesModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.DELIVERIES',
        title: 'NAVIGATION.ACCOUNTING.DELIVERIES',
        permissions: { rule: 'ROLE_CAN_VIEW_GR_HEADERS', navigateTo: ['/', Path.forbidden] },
        dictionaries: [Dictionary.DELIVERY_STATUSES, Dictionary.DELIVERY_TYPES]
      }
    },
    {
      path: Path.iwtInvoices,
      loadChildren: () => import('../modules/iwt-invoices/iwt-invoices.module').then(m => m.IwtInvoicesModule),
      canActivate: [PermissionsGuard],
      resolve: {
        dictionaries: DictionaryResolverService
      },
      data: {
        breadcrumb: 'NAVIGATION.ACCOUNTING.IWT_INVOICES',
        title: 'NAVIGATION.ACCOUNTING.IWT_INVOICES',
        permissions: {
          rule: 'ROLE_CAN_VIEW_IWT_INVOICES',
          navigateTo: ['/', Path.forbidden]
        },
        dictionaries: [Dictionary.IWT_INVOICE_STATUSES, Dictionary.WAREHOUSE_INVENTORIES]
      }
    }
  ]
};
