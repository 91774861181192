import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AccountingPageComponent } from '@fnc-core/components/accounting-page/accounting-page.component';
import { FulfilmentSideMenuItems } from '@fnc-core/components/fulfilment-page/fulfilment-side-menu-items.constant';

@UntilDestroy()
@Component({
  selector: 'fnc-fulfilment-page',
  templateUrl: './fulfilment-page.component.html'
})
export class FulfilmentPageComponent extends AccountingPageComponent {
  sideMenu = FulfilmentSideMenuItems;
}
