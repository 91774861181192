import { HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Params } from '@angular/router';
import { EntityStore, PaginationResponse } from '@datorama/akita';
import { FiltersService } from '@mm-ui/components';
import { FilterChange, TagValue } from '@mm-ui/components/lib/components/filters/types/filters.interface';
import { ApiService, QueryParamsService } from '@mm-ui/core';
import { URLDescriptor } from '@mm-ui/core/lib/services/api/url-descriptor.interface';
import { untilDestroyed } from '@ngneat/until-destroy';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import { Observable, forkJoin, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { FilterNames } from '../models/filter-names';
import { ListSearchService } from './list-search/list-search.service';
import { WINDOW } from './window/window.provider';
import { UiService } from '@fnc-core/services/ui/state/ui.service';
import { SearchPrefixes } from '@fnc-shared-fnc/constants/search-prefixes.constant';
import { getSortingOrder } from '@fnc-shared-fnc/helpers/tables/tables.helpers';
import { SortProperty } from '@fnc-shared/components/base/base-list.class';
import { PayloadSortConfig } from '@fnc-shared/components/base/base-list.interface';
import { filterTypesMap } from '@fnc-shared/constants/filter-types/filter-types-map.constant';
import { FilterTypes } from '@fnc-shared/constants/filter-types/filter-types.constant';
import { QueryMapConfig } from '@fnc-shared/constants/query-map-config.constant';
import { DefaultMeta, ListPaginationResponse } from '@fnc-shared/interfaces/base-list.interface';
import { PageConfig } from '@fnc-shared/models/page-config';
import { Paged } from '@fnc-shared/models/paged';
import { EntityService } from '@fnc-shared/models/state-entities';

const MAX_NUMBER_OF_EXPORT_REQUEST = 100;

export abstract class BaseListService<Entity, EntityMeta = DefaultMeta> implements EntityService<Entity, EntityMeta> {
  protected urlImportance: URLDescriptor | undefined;
  protected urlList: string | URLDescriptor | undefined;
  protected urlExport: URLDescriptor | undefined;
  protected urlExportDetails: URLDescriptor | undefined;
  protected injector: Injector;
  protected ignoreExportBasePath = false;
  protected searchPrefixes: SearchPrefixes[] = [];
  protected queryParamsState = {};
  protected maxNumberOfExportRequest = MAX_NUMBER_OF_EXPORT_REQUEST;
  protected urlExportTarget = '_blank';

  constructor(
    protected api: ApiService,
    protected store: EntityStore<object, Entity> | null,
    protected uiService: UiService,
    protected queryParamsService?: QueryParamsService,
    protected filtersService?: FiltersService,
    protected listSearchService?: ListSearchService
  ) {
    this.injector = Injector.create({
      providers: [{ provide: WINDOW, useValue: window }]
    });
  }

  get(
    pageConfig: PageConfig,
    filters: FilterChange[] = [],
    options = {},
    extData: RecordNested = {},
    colonParameters?: RecordOf<WeakNumber>
  ): Observable<ListPaginationResponse<Entity, EntityMeta>> {
    return this.api
      .get(this.urlList, colonParameters, { ...pageConfig, ...this.filterToQuery(filters), ...extData }, { ...options })
      .pipe(map(this.mapResponse));
  }

  post(
    pageConfig: PageConfig,
    filters: FilterChange[] = [],
    options = {},
    data: RecordNested = {},
    extData: RecordNested = {},
    colonParameters?: RecordOf<WeakNumber>
  ) {
    return this.api
      .post(this.urlList, colonParameters, data, { ...pageConfig, ...this.filterToQuery(filters), ...extData }, { ...options })
      .pipe(map(this.mapResponse));
  }

  setImportance(id: number, isImportant: boolean) {
    this.uiService.loadingList = true;

    return (this.urlImportance &&
      this.api
        .put(this.urlImportance, { id }, { isImportant })
        .pipe(finalize(() => (this.uiService.loadingList = false)))) as Observable<unknown>;
  }

  updateRow(id: WeakNumber, row: Partial<Entity>) {
    this.store?.update(id, row);
  }

  getEntityRequest(pageParams: PageConfig, filterChanges?: FilterChange[]) {
    return () => {
      this.uiService.loadingList = true;

      return this.get(pageParams, filterChanges).pipe(
        finalize(() => {
          this.uiService.loadingList = false;
        })
      );
    };
  }

  exportList(sorting?: PayloadSortConfig, filters?: FilterChange[], additionalParams?: Params) {
    const exportParams = {
      ...ApiService.defaultQueryParams,
      ...(ApiService.convertToSnakeCase({ ...sorting, ...this.filterToQuery(filters) }) as RecordExtPrimitive)
    };
    this.exportEntity(this.urlExport, exportParams, additionalParams);
  }

  exportDetails(id: number) {
    const exportParams = { ...ApiService.defaultQueryParams };
    this.exportEntity(this.urlExportDetails, exportParams, { id });
  }

  updateMetaData(metaData: Partial<PageConfig>) {
    metaData && this.store.update({ metaData });
  }

  sortRows({ propName, direction }: SortProperty, rows: Partial<Entity>[]) {
    const order = direction === 'desc' ? -1 : 1;
    rows.sort((a, b) => getSortingOrder(a, b, order, propName));

    return rows;
  }

  getExportData(pageConfig: PageConfig, filtersState: FilterChange[]) {
    return this.get(pageConfig, filtersState).pipe(
      untilDestroyed(this),
      switchMap(response => {
        const requests$ = [of(response)];

        for (let i = 2; i <= response.lastPage; i++) {
          if (i > this.maxNumberOfExportRequest) {
            break;
          }
          const request$ = this.get({ page: i, limit: pageConfig.limit }, filtersState);
          requests$.push(request$);
        }

        return forkJoin(requests$);
      }),
      map(responses => {
        let data: Entity[] = [];
        responses.forEach((response: PaginationResponse<Entity>) => (data = data.concat(response.data)));

        return data;
      })
    );
  }

  filterBySearch(params: Params) {
    if (!this.searchPrefixes?.length) {
      return true;
    }

    const rawSearch = params.filters?.[FilterNames.SEARCH];
    const rawParams = {
      ...params,
      filters: {
        ...params.filters,
        [FilterNames.SEARCH]: rawSearch ? this.getCleanSearchValue(rawSearch) : ''
      }
    };

    const isEqualState = isEqual(this.queryParamsState, rawParams);
    this.queryParamsState = rawParams;

    return !isEqualState;
  }

  buildQueryParamsFromFilters(filters: FilterChange[]) {
    return filters.reduce((queryParams, currentFilter, i) => {
      const queryParamSeparator = i ? '&' : '?';
      const queryParamValue = Array.isArray(currentFilter.value)
        ? (currentFilter.value as TagValue[]).map(val => val.id).join(',')
        : currentFilter.value?.toString();

      return `${queryParams}${queryParamSeparator}${currentFilter.name.toLocaleLowerCase()}=${queryParamValue}`;
    }, '');
  }

  protected filterToQuery(filters: FilterChange[]) {
    let filtersParams: RecordWeakNumber;
    let queryParams: RecordWeakNumber = {};

    if (filters === null || filters?.length) {
      filtersParams = this.filtersService.mapFiltersToQueryParams(filters ?? []);
    } else {
      filtersParams = this.queryParamsService?.getParams(QueryMapConfig)?.filters ?? {};
    }

    for (const [key, value] of Object.entries(filtersParams)) {
      queryParams = this.getQueryParameters(key, value, queryParams);
    }

    return queryParams;
  }

  protected getCleanSearchValue(rawValue: string) {
    return this.listSearchService?.getCleanSearchValue(rawValue, this.searchPrefixes) ?? rawValue.trim();
  }

  protected getArrayParam(paramValue: WeakNumber | string[] | number[]) {
    return Array.isArray(paramValue) ? paramValue.join() : paramValue;
  }

  protected getBinParam(paramValue: string | string[], expectedValue: string) {
    const params = Array.isArray(paramValue) ? paramValue : paramValue.split(',');

    return params.map(param => (param === expectedValue ? 1 : 0)).join();
  }

  protected getDateRangeParams(paramValue: string | string[], dateParamKey: string) {
    if (typeof paramValue === 'string') {
      paramValue = paramValue.split(',');
    }

    return {
      [`${dateParamKey}From`]: `${paramValue[0]}T00:00:00.000Z`,
      [`${dateParamKey}To`]: `${paramValue[1]}T23:59:59.999Z`
    };
  }

  protected getDateParams(paramValue: string | string[]) {
    if (typeof paramValue === 'string') {
      paramValue = paramValue.split(',');
    }

    return `${paramValue[0]}T00:00:00.000Z`;
  }

  protected getQueryParameters(paramName: string, paramValue: WeakNumber | string[], paramsAggregator: RecordWeakNumber) {
    let params = { ...paramsAggregator };
    const camelCasedParamName = camelCase(paramName);
    const filterType = filterTypesMap[paramName];

    switch (filterType) {
      case FilterTypes.REGULAR:
        params[camelCasedParamName] = paramValue as string;
        break;
      case FilterTypes.BINARY:
        params[camelCasedParamName] = this.getBinParam(paramValue as string, paramName);
        break;
      case FilterTypes.ARRAY:
        params[camelCasedParamName] = this.getArrayParam(paramValue);
        break;
      case FilterTypes.DATE_RANGE:
        params = { ...params, ...this.getDateRangeParams(paramValue as string | string[], camelCasedParamName) };
        break;
      case FilterTypes.SEARCH:
        params.query =
          this.listSearchService?.getPrefixedSearch(paramValue as string, this.searchPrefixes) ?? (paramValue as string).trim();
        break;
    }

    return params;
  }

  private exportEntity(url: URLDescriptor | string, exportParams: RecordExtPrimitive, colonParameters?: RecordOf<WeakNumber>) {
    const exportUrl = ApiService.applyURLParameters(url, colonParameters, this.ignoreExportBasePath);
    const exportQuery = new HttpParams({
      fromObject: {
        ...exportParams,
        format: 'xlsx'
      }
    });

    (this.injector.get(WINDOW) as Window).open(`${exportUrl}?${exportQuery.toString()}`, this.urlExportTarget);
  }

  private mapResponse(
    this: void,
    { total, extra, page: currentPage, itemsPerPage: perPage, data }: Paged<Entity, EntityMeta>
  ): ListPaginationResponse<Entity, EntityMeta> {
    return {
      total,
      currentPage,
      perPage,
      lastPage: Math.ceil(total / perPage),
      extra,
      data
    };
  }
}
