import { ColorType } from '@mm-ui/components/lib/components/icon/color.type';
import { VendorStatus } from '@fnc-app/modules/core/services/vendors/state/vendor.constants';

export const VendorStatusBgColors: Record<VendorStatus | 'DEFAULT', ColorType> = {
  [VendorStatus.NEW]: 'metro-yellow',
  [VendorStatus.REG_LINK_SENT]: 'orange-tint-80',
  [VendorStatus.REGISTRATION_PAUSED]: 'red-tint-60',
  [VendorStatus.CONTRACT_SIGNED]: 'orange-tint-40',
  [VendorStatus.REGISTERED]: 'green-tint-60',
  [VendorStatus.PRODUCT_DATA_UPLOAD]: 'purple-tint-80',
  [VendorStatus.OFFER_DATA_UPLOAD]: 'purple-tint-40',
  [VendorStatus.LIVE]: 'green-shade-20',
  [VendorStatus.OFFLINE]: 'red',
  [VendorStatus.UNKNOWN]: 'metro-blue',
  DEFAULT: 'metro-blue'
};

export const VendorStatusTextColors: Record<VendorStatus | 'DEFAULT', ColorType> = {
  [VendorStatus.NEW]: 'black',
  [VendorStatus.REG_LINK_SENT]: 'black',
  [VendorStatus.REGISTRATION_PAUSED]: 'black',
  [VendorStatus.CONTRACT_SIGNED]: 'black',
  [VendorStatus.REGISTERED]: 'black',
  [VendorStatus.PRODUCT_DATA_UPLOAD]: 'black',
  [VendorStatus.OFFER_DATA_UPLOAD]: 'white',
  [VendorStatus.LIVE]: 'white',
  [VendorStatus.OFFLINE]: 'white',
  [VendorStatus.UNKNOWN]: 'white',
  DEFAULT: 'white'
};
