import { Component, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MmModalService } from '@mm-ui/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MmLoaderService } from '@mm-ui/core';
import {
  CountryStatus,
  PlatformIcons,
  PLCPlatforms,
  PLCSTATUSCOLORS,
  PLCStatuses,
  ProductLifeCycle,
  TabsData
} from './plc-modal.constants';
import { PlcModalService } from './plc-modal.service';
import { SettingsService } from '@fnc-app/modules/core/services/settings/settings.service';
import { Color, ColorDegree, tint } from '@fnc-shared/constants/color';
import { GtmService } from '@fnc-core/services/google-analytics/gtm/gtm.service';
import {
  GA360Country,
  GAActionType,
  GACategory,
  GAEventName,
  GAEventType,
  GALabel
} from '@fnc-core/services/google-analytics/google-analytics.constants';
import { SupplyOrderDraftItem } from '@fnc-app/modules/supply-order-drafts/state/supply-order-draft.model';
import { Replenishment } from '@fnc-app/modules/replenishment/state/replenishment.model';

@UntilDestroy()
@Component({
  selector: 'fnc-plc-status-modal',
  templateUrl: './plc-status-modal.component.html',
  styleUrls: ['./plc-status-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlcStatusModalComponent implements OnDestroy {
  @ViewChild('modalTemplate', { static: true }) modalTemplateRef: TemplateRef<ImplicitAny>;

  modalRef: BsModalRef;
  plcData: ProductLifeCycle;
  tabsData: TabsData[] = [];
  activeTab = '';
  objectKeys = Object.keys;
  objectValues = Object.values;
  pageTitle = '';
  gaPagePrefix = '';
  rowData: Replenishment | SupplyOrderDraftItem = null;
  readonly translationParams: RecordExtPrimitive;

  private readonly modalOptions: ModalOptions = { ignoreBackdropClick: true };

  constructor(
    private readonly modalService: MmModalService,
    private readonly loader: MmLoaderService,
    private readonly plcModalService: PlcModalService,
    readonly settingsService: SettingsService,
    private readonly translateService: TranslateService,
    private readonly gtmService: GtmService
  ) {}

  open(row: Replenishment | SupplyOrderDraftItem, gaDetails: { pageTitle: string; gaPagePrefix: string }) {
    if (row?.sku) {
      this.rowData = row;
      this.loader.startGlobal();
      this.tabsData = [];
      this.plcModalService
        .list(this.rowData.sku)
        .pipe(
          untilDestroyed(this),
          finalize(() => this.loader.stop())
        )
        .subscribe((response: ProductLifeCycle) => {
          if (!this.isEmptyObject(response)) {
            this.plcData = this.convertKeysToUpperCase(this.getPLCSorted(response));
            const lifeCycleData: string[] = Object.keys(this.plcData);
            if (lifeCycleData?.length) {
              this.activeTab = lifeCycleData[0] || '';
              this.setTabHeaders(lifeCycleData);
              this.modalRef = this.modalService.show(this.modalTemplateRef, this.modalOptions);
            }
            this.pageTitle = gaDetails.pageTitle;
            this.gaPagePrefix = gaDetails.gaPagePrefix;

            this.gtmService.pushEvent(GAEventType.STANDARD_EVENT, {
              GA_eventname: GAEventName.INTERACTION,
              GA360_country: GA360Country,
              category: GACategory.PRODUCT_LIFE_CYCLE,
              action: GAActionType[`${this.gaPagePrefix}_PLC_OPEN` as keyof typeof GAActionType],
              label: GALabel.UNDEFINED,
              pagetitle: this.pageTitle
            });
          }
        });
    }
  }

  closeModal() {
    this.modalRef?.hide();
    this.tabsData = [];
  }

  setTabHeaders(data: string[]) {
    data.forEach((country: string) => {
      const tab: TabsData = {
        name: country,
        label: country,
        iconKind: 'bullet',
        iconColor: this.getIconColor(country)
      };
      this.tabsData.push(tab);
    });
  }

  activeTabChange(event: string) {
    this.gtmService.pushEvent(GAEventType.STANDARD_EVENT, {
      GA_eventname: GAEventName.INTERACTION,
      GA360_country: GA360Country,
      category: GACategory.PRODUCT_LIFE_CYCLE,
      action: GAActionType[`${this.gaPagePrefix}_PLC_COUNTRY_SWITCH` as keyof typeof GAActionType],
      label: GALabel.UNDEFINED,
      pagetitle: this.pageTitle
    });
    this.activeTab = event;
  }

  getSalesChannelIcon(platform: string) {
    let icon = '';
    switch (platform) {
      case PLCPlatforms.AMAZON: {
        icon = PlatformIcons.AMAZON;
        break;
      }
      case PLCPlatforms.MARKETPLACE: {
        icon = PlatformIcons.MARKETPLACE;
        break;
      }
      default:
        break;
    }

    return icon;
  }

  displaySalesChannelIcon(platform: string) {
    return Object.values(PLCPlatforms).includes(platform as PLCPlatforms);
  }

  getTagTextColor() {
    return Color.GREY;
  }

  getStatusIconColor(status: PLCStatuses) {
    switch (status) {
      case PLCStatuses.DELIST:
        return tint(Color.ORANGE, ColorDegree.L60);
      case PLCStatuses.INACTIVE:
        return tint(Color.RED, ColorDegree.L60);
      case PLCStatuses.UNDECIDED:
        return tint(Color.RED, ColorDegree.L60);
      default:
        return tint(Color.GREEN, ColorDegree.L60);
    }
  }

  getMotiveLabel(motive: string) {
    const labelValue = this.translateService.instant(`SUPPLY_CHAIN.REPLENISHMENT.INTENTION_MOTIVE.${motive.toUpperCase()}`) as string;

    return !labelValue?.includes('SUPPLY_CHAIN.REPLENISHMENT') ? labelValue : '';
  }

  getToolTipStatus(intention: string): string {
    return this.translateService.instant(
      `SUPPLY_CHAIN.REPLENISHMENT.LIST.PRODUCT_LIFE_CYCLE_STATUS.TOOLTIP_POPUP.${intention.toUpperCase()}`
    ) as string;
  }

  ngOnDestroy() {
    this.closeModal();
  }

  private getIconColor(country: string) {
    const countryData = this.plcData[country] || [];
    let anyNotActive = false;
    let allNotActive = true;

    for (const entry of countryData) {
      if (
        entry?.currentIntention?.status !== PLCStatuses.ACTIVE ||
        (entry?.nextIntention && entry?.nextIntention?.status !== PLCStatuses.ACTIVE)
      ) {
        anyNotActive = true;
      }
      if (
        entry?.currentIntention?.status === PLCStatuses.ACTIVE ||
        (entry?.nextIntention && entry?.nextIntention?.status === PLCStatuses.ACTIVE)
      ) {
        allNotActive = false;
      }
    }

    if (allNotActive) {
      return PLCSTATUSCOLORS.INACTIVE;
    } else if (anyNotActive) {
      return PLCSTATUSCOLORS.AMBER;
    } else {
      return PLCSTATUSCOLORS.ACTIVE;
    }
  }

  private isEmptyObject(obj: object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  private getPLCSorted(productLifeCycle: ProductLifeCycle) {
    const comparePlatforms = (a: CountryStatus, b: CountryStatus): number =>
      a?.platform === PLCPlatforms.MARKETPLACE
        ? -1
        : b?.platform === PLCPlatforms.MARKETPLACE
          ? 1
          : a?.platform?.localeCompare(b?.platform) ?? 0;

    const sortedProductLifeCycle: ProductLifeCycle = {};

    const sortPlatforms = (platforms: CountryStatus[] | undefined, compareFunction: (a: CountryStatus, b: CountryStatus) => number) => {
      return platforms ? platforms?.sort(compareFunction) : [];
    };

    if (productLifeCycle) {
      for (const country in productLifeCycle) {
        if (Object.hasOwn(productLifeCycle, country)) {
          sortedProductLifeCycle[country] = sortPlatforms(productLifeCycle[country], comparePlatforms);
        }
      }
    }

    return sortedProductLifeCycle;
  }

  private convertKeysToUpperCase(plcData: ProductLifeCycle): ProductLifeCycle {
    const result: ProductLifeCycle = {};
    const plcDataKeys = Object.keys(plcData);
    if (plcDataKeys.length) {
      plcDataKeys.forEach(key => {
        const uppercaseKey = key.toUpperCase();
        result[uppercaseKey] = plcData?.[key] ?? [];
      });
    }

    return result;
  }
}
