import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { MmModalService } from '@mm-ui/components';
import { ButtonKind } from '@mm-ui/components/lib/components/button/mm-button.directive';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessagesPosition } from './modal-confirm-window.constant';
import { FormSubmitResponse } from '@fnc-app/types/form';

const MAX_LENGTH = 255;

@Component({
  selector: 'fnc-modal-confirm-window',
  templateUrl: './modal-confirm-window.component.html',
  styleUrls: ['./modal-confirm-window.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalConfirmWindowComponent implements OnInit {
  @ViewChild('confirmModal', { static: true }) template: TemplateRef<ImplicitAny>;
  @Input() title = '';
  @Input() isSubmitEnabled = true;
  @Input() isReasonRequired = true;
  @Input() withoutReason = false;
  @Input() messages: string[] = [];
  @Input() messagesPosition = MessagesPosition.START;
  @Input() label: string = _('COMMON.CONFIRM_WINDOW.REASON');
  @Input() placeholder = _('COMMON.CONFIRM_WINDOW.REASON_PLACEHOLDER');
  @Input() minLength?: number;
  @Input() maxLength = MAX_LENGTH;
  @Input() confirmButtonText = 'COMMON.CONFIRM_WINDOW.CONFIRM';
  @Input() confirmButtonKind: ButtonKind = 'primaryRaised';
  @Output() success = new EventEmitter<ConfirmResult>();
  form: FormGroup;
  modalRef: BsModalRef;

  messagesPositionsEnum = MessagesPosition;

  constructor(
    protected formBuilder: FormBuilder,
    protected modalService: MmModalService
  ) {}

  ngOnInit() {
    const validators: ValidationErrors[] = [];
    this.isReasonRequired && !this.withoutReason && validators.push(Validators.required);
    this.isReasonRequired && this.minLength && validators.push(Validators.minLength(this.minLength));
    this.form = this.formBuilder.group({
      reason: ['', [...validators]]
    });
  }

  open(text = '') {
    this.showModal(text);
  }

  hide() {
    this.modalRef.hide();
  }

  onSubmit({ valid, value }: FormSubmitResponse<ConfirmFormModel>) {
    if (valid) {
      this.success.emit({ isSubmitted: true, reason: value.reason });
    } else {
      this.form.get('reason').markAsDirty();
    }
  }

  protected showModal(text: string) {
    this.form.reset();
    this.form.patchValue({ reason: text });
    this.form.get('reason').markAsPristine();
    this.modalRef = this.modalService.show(this.template, { animated: false });
  }
}

export interface ConfirmResult {
  isSubmitted: boolean;
  reason?: string;
}

export interface ConfirmFormModel {
  reason: string;
}
