import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { PLCStatusColors } from '../plc-status-modal/plc-modal.constants';
import { PLCStatusMultiStep } from './plc-status-multi-step.model';
import { DataLayerData } from '@fnc-app/modules/core/services/google-analytics/google-analytics.interfaces';
import { PLCStatus } from '@fnc-app/modules/replenishment/state/replenishment.model';
import { DASH_SYMBOL } from '@fnc-shared/constants/symbols';

@Component({
  selector: 'fnc-plc-status-multi-step',
  templateUrl: './plc-status-multi-step.component.html',
  styleUrls: ['./plc-status-multi-step.component.scss']
})
export class PlcStatusMultiStepComponent {
  readonly dashSymbol = DASH_SYMBOL;

  @Input() plcStatuses: PLCStatusMultiStep[] = [];
  @Output() checkStatus: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('plcStatusWarningInfoToolTipTemplate', { static: true }) plcStatusWarningInfoToolTipTemplate: TemplateRef<ImplicitAny>;

  componentDataLayerData: DataLayerData;

  getStatusIcon(status?: PLCStatus) {
    return PLCStatusColors[status];
  }

  checkStatusAction(event: MouseEvent) {
    this.checkStatus.emit(event);
  }

  isElementPreferredInventory(elementIndex: number) {
    return this.plcStatuses.length > 0 && elementIndex === 0;
  }
}
