import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  MmActionCardModule,
  MmActionTableMenuModule,
  MmAlertModule,
  MmBarModule,
  MmButtonModule,
  MmCheckboxModule,
  MmContextualMenuModule,
  MmDataTableModule,
  MmDatepickerModule,
  MmDirectivesModule,
  MmDropZoneModule,
  MmEditableTableModule,
  MmFilePreviewModule,
  MmFileTileModule,
  MmFiltersModule,
  MmFlagModule,
  MmFormFieldModule,
  MmHorizontalHeaderModule,
  MmIconModule,
  MmInfoBoxModule,
  MmLoaderModule,
  MmModalModule,
  MmNavigationHeaderBreadcrumbModule,
  MmNoDataContentModule,
  MmOverlayPanelModule,
  MmPageModule,
  MmPanelModule,
  MmPipesModule,
  MmSegmentedControlModule,
  MmSelectModule,
  MmSideMenuModule,
  MmSidebarModule,
  MmSkeletonLoaderModule,
  MmStarModule,
  MmTabModule,
  MmTabContentModule,
  MmTabgroupModule,
  MmTableModule,
  MmTagModule,
  MmTagListModule,
  MmTextareaModule,
  MmToggleButtonModule,
  MmTooglePanelModule,
  MmTypeaheadModule,
  MmUploaderModule,
  MmVerticalHeaderModule,
  MmTabgroupContentModule,
  MmExpandableMessageModule,
  MmCustomEditableTableModule
} from '@mm-ui/components';
import { DictionaryModule, PermissionsModule, MmSpinnerModule } from '@mm-ui/core';
import { IMaskModule } from 'angular-imask';

export const externalExport = [
  CommonModule,
  RouterModule,
  MmBarModule,
  MmButtonModule,
  MmNavigationHeaderBreadcrumbModule,
  MmPanelModule,
  MmTabModule,
  MmTabContentModule,
  MmTabgroupModule,
  MmTabgroupContentModule,
  MmDataTableModule,
  MmPageModule,
  MmTagModule,
  MmTagListModule,
  MmIconModule,
  MmContextualMenuModule,
  MmActionTableMenuModule,
  MmModalModule,
  MmFormFieldModule,
  MmDatepickerModule,
  MmSidebarModule,
  MmVerticalHeaderModule,
  MmTextareaModule,
  MmAlertModule,
  IMaskModule,
  MmLoaderModule,
  MmTypeaheadModule,
  MmInfoBoxModule,
  MmSpinnerModule,
  MmCheckboxModule,
  MmStarModule,
  MmPipesModule,
  PermissionsModule,
  MmSelectModule,
  MmSegmentedControlModule,
  MmFiltersModule,
  PermissionsModule,
  MmTooglePanelModule,
  MmUploaderModule,
  MmDropZoneModule,
  MmFilePreviewModule,
  MmNoDataContentModule,
  MmFileTileModule,
  MmEditableTableModule,
  MmOverlayPanelModule,
  MmFlagModule,
  MmActionCardModule,
  MmDirectivesModule,
  MmSkeletonLoaderModule,
  DictionaryModule,
  MmToggleButtonModule,
  MmHorizontalHeaderModule,
  MmSideMenuModule,
  MmTableModule,
  MmExpandableMessageModule,
  MmCustomEditableTableModule
];
